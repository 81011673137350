import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable, of } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import * as firebase from 'firebase';
import * as _ from 'lodash';

import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmationDialog} from './confirmation-dialog.component';

@Component({
  selector: 'app-productbuild',
  templateUrl: './productbuild.component.html',
  styleUrls: ['./productbuild.component.css']
})
export class ProductbuildComponent implements OnInit {

  public pcForm: FormGroup;
  prdkeyid: any;
  prdbrand: any;
  prdcategory: any;
  prdcollection: any;
  prcollection: any;
  filetot: number;
  prdcolor: any ="MC";
  prdsize: any;
  purrate: any;
  prdrate: any;
  prdcode: any;
  prdestrate: any;
  prdestrate2: any;
  prdqntyshop1: number;
  prdqntygodown: number;
  prdqntyshop2: number;
  favoriteTag: string;
  tags: string[] = ['Trending', 'Popular', 'New', 'None'];

  enableUpdate: boolean = false;
  
  hlprate: any;
  hlpgst: any;

  prdhsncode: any;
  prdgst: any =5;
  prdigst: any;
  prdunit1: any=1;
  prdunit2: any="PC(S)";

  discreseller: any;
  discretail: any;
  discmrp: any;
  discwholesale: any;
  total: any;

  modno: any;
  lblcode: any;
  suppcode: any;

  sizechartname: any;
  sizchrtname: any='';
 
  streseller: any;
  stretail: any;
  stmrp: any;
  stwholesale: any;
  yyy: any;
  zzz: any;
  rrr: any;
  mmm: any;
  kkkk: any;
  qqq: any;
  www: any;
  ddd: any;
  aaa: any;

  yyy1: any;
  zzz1: any;
  rrr1: any;
  mmm1: any;
  kkkk1: any;
  qqq1: any;
  www1: any;
  ddd1: any;
  aaa1: any;

  yyy2: any;
  zzz2: any;
  rrr2: any;
  mmm2: any;
  kkkk2: any;
  qqq2: any;
  www2: any;
  ddd2: any;
  aaa2: any;


  yyy3: any;
  zzz3: any;
  rrr3: any;
  mmm3: any;
  kkkk3: any;
  qqq3: any;
  www3: any;
  ddd3: any;
  aaa3: any;

  discpmrp: any;
  discrmrp: any;
  discmrporg: any;

  stpretail: any;
  strretail: any;
  stretailorg: any;

  stpreseller: any;
  strreseller: any;
  stresellerorg: any;

  stpwholesale: any;
  strwholesale: any;
  stwholesaleorg: any;

  purwithgst: any;
  profmrp: number;
  profretail: number;
  profreseller: number;
  profwholesale: number;

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder, 
    private dialog: MatDialog,
    private snackBar: MatSnackBar){ }

  brndcollection: any;
  ctrycollection: any;
 
  purchrate(){
  this.purwithgst=this.purrate+(this.purrate*(this.prdgst/100))

  this.profmrp =50;
  this.profretail =45;
  this.profreseller =20;
  this.profwholesale =15;
  this.stmrp=(this.purwithgst+(this.purwithgst*(this.profmrp/100))).toFixed(0)
  this.prdrate=this.stmrp;
  this.stretail=(this.purwithgst+(this.purwithgst*(this.profretail/100))).toFixed(0)
  this.streseller=(this.purwithgst+(this.purwithgst*(this.profreseller/100))).toFixed(0)
  this.stwholesale=(this.purwithgst+(this.purwithgst*(this.profwholesale/100))).toFixed(0)

}
profmrpchng(){
this.stmrp=(this.purwithgst+(this.purwithgst*(this.profmrp/100))).toFixed(0)
this.prdrate=this.stmrp;
}
stmrpchng(){
  //newly added//
  //this.profretail=Number(((((this.stmrp-this.purwithgst)/this.purwithgst)*100)-5).toFixed(0));
  this.stretail=Number((this.stmrp-(((this.stmrp)*5)/100)).toFixed(0));
  //newly added//
  this.prdrate=this.stmrp;

}
profretailchng(){
  this.stretail=(this.purwithgst+(this.purwithgst*(this.profretail/100))).toFixed(0)
  }
  profresellerchng(){
    this.streseller=(this.purwithgst+(this.purwithgst*(this.profreseller/100))).toFixed(0)
    }

    profwholesalechng(){
      this.stwholesale=(this.purwithgst+(this.purwithgst*(this.profwholesale/100))).toFixed(0)
      }

swholesale(){
               //newly added//
  //this.profreseller=Number(((((this.stwholesale-this.purwithgst)/this.purwithgst)*100)+5).toFixed(0));
  this.streseller=Number((this.stwholesale+(((this.stwholesale)*5)/100)).toFixed(0));
  //newly added//
      }

      ngAfterViewInit() {
        var el =document.getElementsByClassName('rightsidebar2') as HTMLCollectionOf<HTMLElement>;
        el[0].style.cssText="max-width: 950px !IMPORTANT; width: 950px !IMPORTANT;"
        console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ")    
    }

  ngOnInit() {

    this.filetot= 0;

    this.Form();  

    this.favoriteTag=this.tags[3]; // set default radio button checked

    this.brndcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdbrand='';
    this.prdcategory='';
    // this.prcollection = this.afs.collection('Productbase').snapshotChanges()
    // .pipe(
    //   map(actions => actions.map(a => a.payload.doc.data()))
    // );
  }

  
  // Reactive form
  Form() {
    this.pcForm = this.fb.group({
      productbrand: [''],
      productcategory: [''],
      productsizechartname: [''],
      productcode: [''],
      productcolor: [''],
      productimages: [''],
      purchaserate: [''],
      productrate: [''],
      productestimaterate: [''],
      productestimaterate2: [''],
      productsize: [''],
      productqntygodown: [''],
      productqntyshop1: [''],
      productqntyshop2: [''],

      productmodelno: [{value:''}],
      productlabelcode: [{value:''}],
            productsuppliercode: [{value:''}],

      producthsncode: [{value:''}],
      productgst: [{value:''}],
      productigst: [{value:''}],
      productunit1: [{value:''}],
      productunit2: [{value:''}],
      discountreseller: [{value:''}],
      discountretail: [{value:''}],
      discountmrp: [{value:''}],
      discountwholesale: [{value:''}],

      saletypereseller: [{value:''}],
      saletyperetail: [{value:''}],
      saletypemrp: [{value:''}],
      saletypewholesale: [{value:''}],

      discountmrporg: [{value:''}],
      discountpmrp: [{value:''}],
      discountrmrp: [{value:''}],

      saletyperetailorg: [{value:''}],
      saletypepretail: [{value:''}],
      saletyperretail: [{value:''}],

      saletyperesellerorg: [{value:''}],
      saletypepreseller: [{value:''}],
      saletyperreseller: [{value:''}],

      saletypewholesaleorg: [{value:''}],
      saletypepwholesale: [{value:''}],
      saletyperwholesale: [{value:''}],

      purchasewithgst: [{value:''}],
      profitmrp: [{value:''}],
      profitretail: [{value:''}],
      profitreseller: [{value:''}],
      profitwholesale: [{value:''}],
    })
  }

  // Accessing form control using getters
  get productbrand() {
    return this.pcForm.get('productbrand');
  }
  get productcategory() {
    return this.pcForm.get('productcategory');
  }
  get productsizechartname() {
    return this.pcForm.get('productsizechartname');
  }
  get productcode() {
    return this.pcForm.get('productcode');
  }
  get productcolor() {
    return this.pcForm.get('productcolor');
  }
  get productimages() {
    return this.pcForm.get('productimages');
  }
  get productestimaterate() {
    return this.pcForm.get('productestimaterate');
  }
  get productestimaterate2() {
    return this.pcForm.get('productestimaterate2');
  }
  get productqntygodown() {
    return this.pcForm.get('productqntygodown');
  }
  get productqntyshop1() {
    return this.pcForm.get('productqntyshop1');
  }
  get productqntyshop2() {
    return this.pcForm.get('productqntyshop2');
  }

get productmodelno() {
  return this.pcForm.get('productmodelno');
}

get productlabelcode() {
  return this.pcForm.get('productlabelcode');
}
get productsuppliercode() {
  return this.pcForm.get('productsuppliercode');
}
get producthsncode() {
  return this.pcForm.get('producthsncode');
}

get productgst() {
  return this.pcForm.get('productgst');
}

get productigst() {
  return this.pcForm.get('productigst');
}

get productunit1() {
  return this.pcForm.get('productunit1');
}

get productunit2() {
  return this.pcForm.get('productunit2');
}

get discountreseller(){
  return this.pcForm.get('discountreseller');
}

get discountretail(){
  return this.pcForm.get('discountretail');
}

get discountmrp(){
  return this.pcForm.get('discountmrp');
}

get discountwholesale(){
  return this.pcForm.get('discountwholesale');
}

get saletypemrp(){
  return this.pcForm.get('saletypemrp');
}

get saletyperetail(){
  return this.pcForm.get('saletyperetail');
}

get saletypereseller(){
  return this.pcForm.get('saletypereseller');
}

get saletypewholesale(){
  return this.pcForm.get('saletypewholesale');
}

get discountpmrp(){
  return this.pcForm.get('discountpmrp');
}

get discountrmrp(){
  return this.pcForm.get('discountrmrp');
}
get discountmrporg(){
  return this.pcForm.get('discountmrporg');
}

get saletyperetailorg(){
  return this.pcForm.get('saletyperetailorg');
}

get saletypepretail(){
  return this.pcForm.get('saletypepretail');
}
get saletyperretail(){
  return this.pcForm.get('saletyperretail');
}

get saletyperesellerorg(){
  return this.pcForm.get('saletyperesellerorg');
}

get saletypepreseller(){
  return this.pcForm.get('saletypepreseller');
}
get saletyperreseller(){
  return this.pcForm.get('saletyperreseller');
}

get saletypewholesaleorg(){
  return this.pcForm.get('saletypewholesaleorg');
}

get saletypepwholesale(){
  return this.pcForm.get('saletypepwholesale');
}
get saletyperwholesale(){
  return this.pcForm.get('saletyperwholesale');
}

get purchasewithgst(){
  return this.pcForm.get('purchasewithgst');
}
get profitretail(){
  return this.pcForm.get('profitretail');
}
get profitreseller(){
  return this.pcForm.get('profitreseller');
}
get profitwholesale(){
  return this.pcForm.get('profitwholesale');
}



  changeBrand(br){
    this.ctrycollection = this.afs.collection('Brands', ref => ref.where('brandname', "==" , br)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  this.prdsize='';
  this.sizchrtname ='';
  this.prdcategory ='';
  this.resetproductdetails();
  console.log(JSON.stringify(this.ctrycollection));
  }

  changeCategory(ct,br){
    console.log(this.prdbrand);

    ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
    console.log(ct);

    
    this.afs.collection('Brands').doc(br).get().toPromise().then(doc => {
      if(doc.data().categories){
        for(let i=0;i<doc.data().categories.length;i++){
          if(doc.data().categories[i].categoryname == ct){
            this.sizchrtname = doc.data().categories[i].sizechart
            console.log(this.sizchrtname)
          }
          if(doc.data().categories[i].categoryname != ct){
          }
      }
      
    }
  })

    this.prcollection = this.afs.collection('Productbuild', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


this.resetproductdetails();
  }

// Reset form's values
ResetForm() {
  this.urls = [];
  this.filetot= 0;
  this.pcForm.reset();
}


submitProductbuildData() {
  this.add(this.pcForm.value.productid);
  //this.ResetForm();  // Reset form when clicked on reset button
  //this.resetproductdetails();
};

discmrporgchng(){
  this.discmrp = this.discmrporg;
}




discpmrpchng(){
  this.discrmrp = (this.discmrporg*(this.discpmrp/100)).toFixed(0);
}
discrmrpchng(){
  this.discmrp = (this.discmrporg-this.discrmrp).toFixed(0);
  this.discpmrp = (isNaN(Number((this.discmrporg-this.discmrp)/this.discmrporg)*100) ? 0 : Number((this.discmrporg-this.discmrp)/this.discmrporg)*100).toFixed(2);
}

stpretailchng(){
  this.strretail = (this.stretailorg*(this.stpretail/100)).toFixed(0);
}
strretailchng(){
  this.stretail = (this.stretailorg-this.strretail).toFixed(0);
  this.stpretail = (isNaN(Number((this.stretailorg-this.stretail)/this.stretailorg)*100) ? 0 : Number((this.stretailorg-this.stretail)/this.stretailorg)*100).toFixed(2);
}

stpresellerchng(){
  this.strreseller = (this.stresellerorg*(this.stpreseller/100)).toFixed(0);
}
strresellerchng(){
  this.streseller = (this.stresellerorg-this.strreseller).toFixed(0);
  this.stpreseller = (isNaN(Number((this.stresellerorg-this.streseller)/this.stresellerorg)*100) ? 0 : Number((this.stresellerorg-this.streseller)/this.stresellerorg)*100).toFixed(2);
}

stpwholesalechng(){
  this.strwholesale = (this.stwholesaleorg*(this.stpwholesale/100)).toFixed(0);
}
strwholesalechng(){
  this.stwholesale = (this.stwholesaleorg-this.strwholesale).toFixed(0);
  this.stpwholesale = (isNaN(Number((this.stwholesaleorg-this.stwholesale)/this.stwholesaleorg)*100) ? 0 : Number((this.stwholesaleorg-this.stwholesale)/this.stwholesaleorg)*100).toFixed(2);
}

add(id) {
   let ids =this.afs.createId();
  this.afs.collection("Products").doc(ids).set({

    0:"0",
    KEYID:ids,
    brand:this.prdbrand,
    category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
    bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
      images:this.urls, //images
      size:this.prdsize,
      color:this.prdcolor,
      // code:this.prdcolor+this.prdsize,
      code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
      prate:isNaN(Number(this.purrate)) ? 0 : Number(this.purrate),
      rate:isNaN(Number(this.prdrate)) ? 0 : Number(this.prdrate),
      estrate:isNaN(Number(this.prdestrate)) ? 0 : Number(this.prdestrate),
      estrate2:isNaN(Number(this.prdestrate2)) ? 0 : Number(this.prdestrate2),
      qntyshop1:isNaN(Number(this.prdqntyshop1)) ? 0 : Number(this.prdqntyshop1),
      qntyshop2:isNaN(Number(this.prdqntyshop2)) ? 0 : Number(this.prdqntyshop2),
      qntygodown:isNaN(Number(this.prdqntygodown)) ? 0 : Number(this.prdqntygodown),
      tag:this.favoriteTag,

      // modelno: this.modno.toUpperCase(),
      // labelcode: this.lblcode.toUpperCase(),

      modelno: (this.modno!=null) ? (this.modno.toUpperCase()):'',
      labelcode: (this.lblcode!=null) ? (this.lblcode.toUpperCase()):'',
      suppliercode: (this.suppcode!=null) ? (this.suppcode.toUpperCase()):'',

      hsncode: (this.prdhsncode!=null) ? (this.prdhsncode.toUpperCase()):'',
      gst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
      //igst:isNaN(Number(this.prdigst)) ? 0 : Number(this.prdigst),
      igst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
      unit1: Number(this.prdunit1),
      unit2: this.prdunit2,
      discount:{
        reseller:isNaN(Number((this.prdrate-this.streseller)/this.prdrate)*100) ? 0 : Number((this.prdrate-this.streseller)/this.prdrate)*100,
        retail:isNaN(Number((this.prdrate-this.stretail)/this.prdrate)*100) ? 0 : Number((this.prdrate-this.stretail)/this.prdrate)*100,
        mrp:isNaN(Number((this.prdrate-this.stmrp)/this.prdrate)*100) ? 0 : Number((this.prdrate-this.stmrp)/this.prdrate)*100,
        wholesale:isNaN(Number((this.prdrate-this.stwholesale)/this.prdrate)*100) ? 0 : Number((this.prdrate-this.stwholesale)/this.prdrate)*100,
      },
      discountrate:{
        mrp:isNaN(Number(this.prdrate-this.stmrp)) ? 0 : Number(this.prdrate-this.stmrp),
        retail:isNaN(Number(this.prdrate-this.stretail)) ? 0 : Number(this.prdrate-this.stretail),
        reseller:isNaN(Number(this.prdrate-this.streseller)) ? 0 : Number(this.prdrate-this.streseller),
        wholesale:isNaN(Number(this.prdrate-this.stwholesale)) ? 0 : Number(this.prdrate-this.stwholesale),
      },
      salerate:{
        reseller:isNaN(Number(this.streseller)) ? 0 : Number(this.streseller),
        retail:isNaN(Number(this.stretail)) ? 0 : Number(this.stretail),
        mrp:isNaN(Number(this.stmrp)) ? 0 : Number(this.stmrp),
        wholesale:isNaN(Number(this.stwholesale)) ? 0 : Number(this.stwholesale),
      },
      //newly added
      sizechart: String(this.sizchrtname).substring(0,2),
      //newly added

  }, { merge: true })

    .catch((err) => {
      console.log(err);
    })


    this.afs.collection("Barcodebasket").doc(this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize).set({
      category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
      bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
      size:this.prdsize,

      //newly added
      sizechart: this.sizchrtname,
      labelcode: (this.lblcode!=null) ? (this.lblcode.toUpperCase()):'',
      suppliercode: (this.suppcode!=null) ? (this.suppcode.toUpperCase()):'',
      //newly added

      color:this.prdcolor,
      // code:this.prdcolor+this.prdsize,
      code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
      rate:Number(this.prdrate),

      //qntycount:Number(Number(this.prdqntyshop1)),
      qntycount:Number(Number(this.prdqntyshop1)+Number(this.prdqntyshop2)+Number(this.prdqntygodown))
    })
   

    //---------------------tagbasket-----------------
    // if(this.favoriteTag!='None'){
    //   this.afs.collection("Tagbasket").doc(ids).set({
    //     brand:this.prdbrand,
    //     category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
    //     bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
    //     code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
    //     tag:this.favoriteTag,
    //   })
    // }
    //---------------------tagbasket-----------------

  //this.update(id);
}

clearbarcodebasket(){
  var deletearray=[];
  this.afs.collection('Barcodebasket').get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        deletearray.push(doc.data().code);
      })
    }
  }).then(()=>{
    for(let i=0; i<deletearray.length; i++){
      this.afs.collection('Barcodebasket').doc(String(deletearray[i])).delete();
    }
  });
 }

 
resetproductdetails(){
  this.prdcolor='MC';
  this.prdsize='';
  this.modno='';
  this.prdhsncode='';
  this.lblcode='';
  this.suppcode='';
  this.prdgst=5;
  this.prdigst='';
  this.prdrate='';
  this.prdestrate='';
  this.prdestrate2='';
  this.prdqntyshop1=null;
  this.prdqntyshop2=null;
  this.prdqntygodown=null;
  this.discmrp='';
  this.discretail='';
  this.discreseller='';
  this.discwholesale='';
  this.stmrp='';
  this.stretail='';
  this.streseller='';
  this.stwholesale='';
  this.favoriteTag=this.tags[3];
  this.urls = [];
  this.filetot= 0;

  this.enableUpdate=false;

  this.purrate='';
}

helperreset(){
  this.hlprate='';
  this.hlpgst='';
}

gethlporiginalcost(){
  return this.hlprate * (100/(100 + this.hlpgst));
}

gethlpgstcost(){
  return this.hlprate - (this.hlprate * (100/(100 + this.hlpgst)));
}

update(pid) {
  console.log(this.prdcode);
  let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
  this.afs.collection("Products", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct).where('code',"==",this.prdcode)).doc(pid).update({
    images:this.urls, //images
    size:this.prdsize,
    color:this.prdcolor,
    //code:this.prdcolor+this.prdsize,
    code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
    prate:isNaN(Number(this.purrate)) ? 0 : Number(this.purrate),
    rate:isNaN(Number(this.prdrate)) ? 0 : Number(this.prdrate),
    estrate:isNaN(Number(this.prdestrate)) ? 0 : Number(this.prdestrate),
    estrate2:isNaN(Number(this.prdestrate2)) ? 0 : Number(this.prdestrate2),
    qntyshop1:isNaN(Number(this.prdqntyshop1)) ? 0 : Number(this.prdqntyshop1),
    qntyshop2:isNaN(Number(this.prdqntyshop2)) ? 0 : Number(this.prdqntyshop2),
    qntygodown:isNaN(Number(this.prdqntygodown)) ? 0 : Number(this.prdqntygodown),
    tag:this.favoriteTag,

    // modelno:this.modno.toUpperCase(),
    // labelcode:this.lblcode.toUpperCase(),
    
    modelno: (this.modno!=null) ? (this.modno.toUpperCase()):'',
    labelcode: (this.lblcode!=null) ? (this.lblcode.toUpperCase()):'',
    suppliercode: (this.suppcode!=null) ? (this.suppcode.toUpperCase()):'',

    hsncode: (this.prdhsncode!=null) ? (this.prdhsncode.toUpperCase()):'',
    gst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
    //igst:isNaN(Number(this.prdigst)) ? 0 : Number(this.prdigst),
    igst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
    unit1:Number(this.prdunit1),
    unit2:this.prdunit2,

    discount:{
      reseller:isNaN(Number((this.prdrate-this.streseller)/this.prdrate)*100) ? 0 : Number((this.prdrate-this.streseller)/this.prdrate)*100,
      retail:isNaN(Number((this.prdrate-this.stretail)/this.prdrate)*100) ? 0 : Number((this.prdrate-this.stretail)/this.prdrate)*100,
      mrp:isNaN(Number((this.prdrate-this.stmrp)/this.prdrate)*100) ? 0 : Number((this.prdrate-this.stmrp)/this.prdrate)*100,
      wholesale:isNaN(Number((this.prdrate-this.stwholesale)/this.prdrate)*100) ? 0 : Number((this.prdrate-this.stwholesale)/this.prdrate)*100,
    },
    discountrate:{
      mrp:isNaN(Number(this.prdrate-this.stmrp)) ? 0 : Number(this.prdrate-this.stmrp),
      retail:isNaN(Number(this.prdrate-this.stretail)) ? 0 : Number(this.prdrate-this.stretail),
      reseller:isNaN(Number(this.prdrate-this.streseller)) ? 0 : Number(this.prdrate-this.streseller),
      wholesale:isNaN(Number(this.prdrate-this.stwholesale)) ? 0 : Number(this.prdrate-this.stwholesale),
    },
    
    salerate:{
      reseller:isNaN(Number(this.streseller)) ? 0 : Number(this.streseller),
      retail:isNaN(Number(this.stretail)) ? 0 : Number(this.stretail),
      mrp:isNaN(Number(this.stmrp)) ? 0 : Number(this.stmrp),
      wholesale:isNaN(Number(this.stwholesale)) ? 0 : Number(this.stwholesale),
    },

  }).then(()=>{
    //this.ResetForm();
    //this.resetproductdetails();
    this.enableUpdate=false;
  })


  this.afs.collection("Barcodebasket").doc(this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize).set({
    category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
    bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
    size:this.prdsize,

    //newly added
    sizechart: this.sizchrtname,
    labelcode: (this.lblcode!=null) ? (this.lblcode.toUpperCase()):'',
    suppliercode: (this.suppcode!=null) ? (this.suppcode.toUpperCase()):'',
    //newly added

    color:this.prdcolor,
    // code:this.prdcolor+this.prdsize,
    code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
    rate:Number(this.prdrate),

    //qntycount:Number(Number(this.prdqntyshop1)),
    qntycount:Number(Number(this.prdqntyshop1)+Number(this.prdqntyshop2)+Number(this.prdqntygodown))
  })

       //---------------------tagbasket-----------------
//   .then(()=>{
//     if(this.favoriteTag!='None'){
//     this.afs.collection("Tagbasket", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct).where('code',"==",this.prdcode)).doc(pid).set({
//       brand:this.prdbrand,
//       category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
//       bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
//       code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
//       tag:this.favoriteTag,
//     }, { merge: true })
//   }
//   if(this.favoriteTag=='None'){
//     this.afs.collection("Tagbasket", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct).where('code',"==",this.prdcode)).doc(pid).delete();
//   }
// })
    //---------------------tagbasket-----------------

}


read(pd,i) {  
  this.prdkeyid = pd.KEYID;
  this.prdbrand = pd.brand;
  this.prdcategory = pd.bcc+' '+pd.category;
  this.urls = pd.images;
  this.prdcode = pd.code;
  this.prdsize = pd.size;
  this.prdcolor = pd.color;
  this.purrate = pd.prate;
  this.prdrate = pd.rate;
  this.prdestrate = pd.estrate;
  this.prdestrate2 = pd.estrate2;
  this.prdqntyshop1 = pd.qntyshop1;
  this.prdqntyshop2 = pd.qntyshop2;
  this.prdqntygodown = pd.qntygodown;
  this.favoriteTag=pd.tag;

  this.modno=pd.modelno;
  this.lblcode=pd.labelcode;
  this.suppcode=pd.suppliercode;
  this.prdhsncode=pd.hsncode;
  this.prdgst=pd.gst;
  this.prdigst=pd.igst;
  this.prdunit1=pd.unit1;
  this.prdunit2=pd.unit2;
  this.discretail=pd.discount["retail"];
  this.discmrp=pd.discount["mrp"];
  this.discreseller=pd.discount["reseller"];
  this.discwholesale=pd.discount["wholesale"];
  this.stretail=pd.saletype["retail"];
  this.stmrp=pd.saletype["mrp"];
  this.streseller=pd.saletype["reseller"];
  this.stwholesale=pd.saletype["wholesale"];
  
  this.filetot = this.urls.length;

}

// prate(prdrate){
//   this.stmrp=((this.prdrate)+((this.prdrate)*(this.prdgst/100)));

//   }

// smrp(stmrp){


// this.yyy=(stmrp - (stmrp * (100/(100 + this.prdgst))))
// this.zzz=(stmrp - this.yyy)

// this.rrr=((this.prdrate)+((this.prdrate)*(this.prdgst/100)))
// this.mmm=stmrp
// this.kkkk=(stmrp - this.yyy)
// this.qqq=(this.rrr-this.kkkk)
// this.ddd=((this.qqq-this.yyy)-((this.qqq-this.yyy)*(this.prdgst/100)))
// this.www= ((this.rrr - this.qqq)+((this.rrr - this.qqq)*(this.prdgst/100)))
// this.aaa= Number((this.ddd/this.prdrate)*100).toFixed(3)
// this.discmrp=this.aaa;
// }

// sretail(stretail){
//   this.yyy1=(stretail - (stretail * (100/(100 + this.prdgst))))
//   this.zzz1=(stretail - this.yyy1)
  
//   this.rrr1=((this.prdrate)+((this.prdrate)*(this.prdgst/100)))
//   this.mmm1=stretail
//   this.kkkk1=(stretail - this.yyy1)
//   this.qqq1=(this.rrr1-this.kkkk1)
//   this.ddd1=((this.qqq1-this.yyy1)-((this.qqq1-this.yyy1)*(this.prdgst/100)))
//   this.www1= ((this.rrr1 - this.qqq1)+((this.rrr1 - this.qqq1)*(this.prdgst/100)))
//   this.aaa1= Number((this.ddd1/this.prdrate)*100).toFixed(3)
//   this.discretail=this.aaa1;
//   }

//   sreseller(streseller){
//     this.yyy2=(streseller - (streseller * (100/(100 + this.prdgst))))
//     this.zzz2=(streseller - this.yyy2)
    
//     this.rrr2=((this.prdrate)+((this.prdrate)*(this.prdgst/100)))
//     this.mmm2=streseller
//     this.kkkk2=(streseller - this.yyy2)
//     this.qqq2=(this.rrr2-this.kkkk2)
//     this.ddd2=((this.qqq2-this.yyy2)-((this.qqq2-this.yyy2)*(this.prdgst/100)))
//     this.www2= ((this.rrr2 - this.qqq2)+((this.rrr2 - this.qqq2)*(this.prdgst/100)))
//     this.aaa2= Number((this.ddd2/this.prdrate)*100).toFixed(3)
//     this.discreseller=this.aaa2;
//     }

//     swholesale(stwholesale){
//       this.yyy3=(stwholesale - (stwholesale * (100/(100 + this.prdgst))))
//       this.zzz3=(stwholesale - this.yyy3)
      
//       this.rrr3=((this.prdrate)+((this.prdrate)*(this.prdgst/100)))
//       this.mmm3=stwholesale
//       this.kkkk3=(stwholesale - this.yyy3)
//       this.qqq3=(this.rrr3-this.kkkk3)
//       this.ddd3=((this.qqq3-this.yyy3)-((this.qqq3-this.yyy3)*(this.prdgst/100)))
//       this.www3= ((this.rrr3 - this.qqq3)+((this.rrr3 - this.qqq3)*(this.prdgst/100)))
//       this.aaa3= Number((this.ddd3/this.prdrate)*100).toFixed(3)
//       this.discwholesale=this.aaa3;
//       }


      prate(prdrate){
  this.stmrp=((this.prdrate));

  }

// smrp(stmrp){


// this.yyy=(stmrp - (stmrp * (100/(100 + this.prdgst))))
// this.zzz=(stmrp - this.yyy)

// this.rrr=((this.prdrate))
// this.mmm=stmrp
// this.kkkk=(stmrp - this.yyy)
// this.qqq=(this.rrr-this.kkkk)
// this.ddd=((this.qqq-this.yyy)-((this.qqq-this.yyy)*(this.prdgst/100)))
// this.www= ((this.rrr - this.qqq)+((this.rrr - this.qqq)*(this.prdgst/100)))
// this.aaa= Number((this.ddd/this.prdrate)*100).toFixed(3)
// this.discmrp=(stmrp/(this.prdrate))*100;
// }

// sretail(stretail){
//   this.yyy1=(stretail - (stretail * (100/(100 + this.prdgst))))
//   this.zzz1=(stretail - this.yyy1)
  
//   this.rrr1=((this.prdrate))
//   this.mmm1=stretail
//   this.kkkk1=(stretail - this.yyy1)
//   this.qqq1=(this.rrr1-this.kkkk1)
//   this.ddd1=((this.qqq1-this.yyy1)-((this.qqq1-this.yyy1)*(this.prdgst/100)))
//   this.www1= ((this.rrr1 - this.qqq1)+((this.rrr1 - this.qqq1)*(this.prdgst/100)))
//   this.aaa1= Number((this.ddd1/this.prdrate)*100).toFixed(3)
//   this.discretail=(stretail/(this.prdrate))*100;
//   }

//   sreseller(streseller){
//     this.yyy2=(streseller - (streseller * (100/(100 + this.prdgst))))
//     this.zzz2=(streseller - this.yyy2)
    
//     this.rrr2=((this.prdrate))
//     this.mmm2=streseller
//     this.kkkk2=(streseller - this.yyy2)
//     this.qqq2=(this.rrr2-this.kkkk2)
//     this.ddd2=((this.qqq2-this.yyy2)-((this.qqq2-this.yyy2)*(this.prdgst/100)))
//     this.www2= ((this.rrr2 - this.qqq2)+((this.rrr2 - this.qqq2)*(this.prdgst/100)))
//     this.aaa2= Number((this.ddd2/this.prdrate)*100).toFixed(3)
//     this.discreseller=(streseller/(this.prdrate))*100;
//     }

    // swholesale(stwholesale){
    //   this.yyy3=(stwholesale - (stwholesale * (100/(100 + this.prdgst))))
    //   this.zzz3=(stwholesale - this.yyy3)
      
    //   this.rrr3=((this.prdrate))
    //   this.mmm3=stwholesale
    //   this.kkkk3=(stwholesale - this.yyy3)
    //   this.qqq3=(this.rrr3-this.kkkk3)
    //   this.ddd3=((this.qqq3-this.yyy3)-((this.qqq3-this.yyy3)*(this.prdgst/100)))
    //   this.www3= ((this.rrr3 - this.qqq3)+((this.rrr3 - this.qqq3)*(this.prdgst/100)))
    //   this.aaa3= Number((this.ddd3/this.prdrate)*100).toFixed(3)
    //   this.discwholesale=(stwholesale/(this.prdrate))*100;
    //   }


delete(pd,i) {
  this.prdkeyid = pd.KEYID;
  console.log(this.prdcode);
  let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
  this.afs.collection("Products", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).doc(this.prdkeyid).delete();
}




checkprd(){
  console.log("hello");
    
  this.enableUpdate=false;

  if(this.prdbrand && this.prdcategory && this.prdcolor && this.prdsize){
    let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
    this.afs.collection('Products', ref => ref.where('brand', '==' , this.prdbrand).where('category', '==' , ct)
    .where('color', '==' , this.prdcolor).where('size', '==' , this.prdsize)).get().toPromise().then(querySnapshot => {
      if (querySnapshot.size > 0) {

        this.enableUpdate=true;

        console.log("hello3");
        querySnapshot.forEach(doc => {
          this.prdkeyid = doc.data().KEYID;
          this.prdbrand = doc.data().brand;
          this.prdcategory = doc.data().bcc+' '+doc.data().category;
          this.urls = doc.data().images;
          this.prdcode = doc.data().code;
          this.prdsize = doc.data().size;
          this.prdcolor = doc.data().color;
          this.purrate = doc.data().prate;
          this.prdrate = doc.data().rate;
          this.prdestrate = doc.data().estrate;
          this.prdestrate2 = doc.data().estrate2;
          this.prdqntyshop1 = doc.data().qntyshop1;
          this.prdqntyshop2 = doc.data().qntyshop2;
          this.prdqntygodown = doc.data().qntygodown;
          this.favoriteTag=doc.data().tag;
        
          this.modno=doc.data().modelno;
          this.lblcode=doc.data().labelcode;
          this.suppcode=doc.data().suppliercode;
          this.prdhsncode=doc.data().hsncode;
          this.prdgst=doc.data().gst;
          this.prdigst=doc.data().igst;
          this.prdunit1=doc.data().unit1;
          this.prdunit2=doc.data().unit2;
          this.discretail=doc.data().discount["retail"];
          this.discmrp=doc.data().discount["mrp"];
          this.discreseller=doc.data().discount["reseller"];
          this.discwholesale=doc.data().discount["wholesale"];

          this.stretail=doc.data().discount["retail"];
          this.stmrp=doc.data().discount["mrp"];
          this.streseller=doc.data().discount["reseller"];
          this.stwholesale=doc.data().discount["wholesale"];

          this.filetot = this.urls.length;
        
          })
      }
    })
}
}

urls = new Array<string>();
detectFiles(event) {
  this.urls = [];
  let files = event.target.files;
  if (files) {
    for (let file of files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
        console.log(this.urls);
        this.filetot = this.urls.length;

      }
      reader.readAsDataURL(file);
    }
  }
}

deleteImage(key){
  this.urls.splice(key,1);
  this.filetot = this.urls.length;
  console.log(this.urls);
}







openDialog(pd,i) {
  const dialogRef = this.dialog.open(ConfirmationDialog,{
    data:{
      message: 'Are you sure you want to delete this product permanently?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {

      this.prdkeyid = pd.KEYID;
  console.log(this.prdcode);
  let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
  this.afs.collection("Products", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).doc(this.prdkeyid).delete();

      this.snackBar.open('Product Deleted Successfully.', 'OK', {
        duration: 2000,
      });
    }
  });
}

}