import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pos/home/home.component';
import { ProductdetailsComponent } from './pos/productdetails/productdetails.component';
import { LedgerdetailsComponent } from './pos/ledgerdetails/ledgerdetails.component';
import { ParticularsComponent } from './pos/particulars/particulars.component';
import { ProductbuildComponent } from './pos/productbuild/productbuild.component';
import { ProductbuildmfcComponent } from './pos/productbuildmfc/productbuildmfc.component';
import { SidebarlComponent } from './pos/sidebarl/sidebarl.component';
import { PaperlayoutComponent } from './pos/paperlayout/paperlayout.component';
import { BillentryComponent } from './pos/billentry/billentry.component';
import { ProductmigrationComponent } from './pos/productmigration/productmigration.component';
import { BarcodelayoutComponent } from './pos/barcodelayout/barcodelayout.component';
import { ProductfiltersComponent } from './pos/productfilters/productfilters.component';
import { BilldetailsComponent } from './pos/billdetails/billdetails.component';
import { EmployeesComponent } from './pos/employees/employees.component';
import { CustomersComponent } from './pos/customers/customers.component';
import { SuppliersComponent } from './pos/suppliers/suppliers.component';
import { SettingsComponent } from './pos/settings/settings.component';
import { BillreturnsComponent } from './pos/billreturns/billreturns.component';
import { BrandscategoriesComponent } from './pos/brandscategories/brandscategories.component';
import { BilldeleteComponent } from './pos/billdelete/billdelete.component';
import { DashboardComponent } from './pos/dashboard/dashboard.component';
import { ProductmigrationoutComponent } from './pos/productmigrationout/productmigrationout.component';
import { ProductmigrationinComponent } from './pos/productmigrationin/productmigrationin.component';
import { ProductmigrationstockComponent } from './pos/productmigrationstock/productmigrationstock.component';
import { ProductmigrationmanageComponent } from './pos/productmigrationmanage/productmigrationmanage.component';
import { BarcodelayoutnewstockComponent } from './pos/barcodelayoutnewstock/barcodelayoutnewstock.component';
import { ReportsComponent } from './pos/reports/reports.component';
import { DisplaybannersComponent } from './pos/displaybanners/displaybanners.component';
import { CustomerprofileComponent } from './pos/customerprofile/customerprofile.component';
import { BarcodelayoutnewprdComponent } from './pos/barcodelayoutnewprd/barcodelayoutnewprd.component';
import { AlterstockComponent } from './pos/alterstock/alterstock.component';
import { LedgerbooksComponent } from './pos/ledgerbooks/ledgerbooks.component';
import { LedgerfilterComponent } from './pos/ledgerfilter/ledgerfilter.component';
import { BookstagsComponent } from './pos/bookstags/bookstags.component';
import { ReportdetailsComponent } from './pos/reportdetails/reportdetails.component';
import { EstimationComponent } from './pos/estimation/estimation.component';
import { TopsellingComponent } from './pos/topselling/topselling.component';
import { ProfitbillsComponent } from './pos/profitbills/profitbills.component';
import { BrandcategoriesmfcComponent } from './pos/brandcategoriesmfc/brandcategoriesmfc.component';
import { CreditbillsComponent } from './pos/creditbills/creditbills.component';
import { BilleditComponent } from './pos/billedit/billedit.component';
import { VouchersComponent } from './pos/vouchers/vouchers.component';
import { SupplierprofileComponent } from './pos/supplierprofile/supplierprofile.component';
import { WalkoutsComponent } from './pos/walkouts/walkouts.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { SecureInnerPagesGuard } from 'src/assets/auth/guard/secure-inner-pages-guard.guard';
import { AuthService } from "../assets/auth/services/auth.service";
import { AuthGuard } from 'src/assets/auth/guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  // {
  //   path: 'allinone',
  //   component: AllinoneComponent, canActivate: [SecureInnerPagesGuard]
  // },
  // {
  //   path: 'login',
  //   component: LoginComponent, canActivate: [SecureInnerPagesGuard]
  // },
  // {
  //   path: 'resetpassword',
  //   component: ResetpasswordComponent, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'register',
  //   component: RegistrationComponent, canActivate: [SecureInnerPagesGuard]
  // },
  // {
  //   path: 'home',
  //   component: HomeComponent, canActivate: [AuthGuard]
  // },
  {
    path: 'sidebarl',
    component: SidebarlComponent,
    children:  [
    {
      path: 'productdetails',
      component: ProductdetailsComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'productbuild', component: ProductbuildComponent, outlet: 'side', canActivate: [AuthGuard]}
      ]
    },
    {
      path: 'alterstock',
      component: AlterstockComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'dashboard',
      component: DashboardComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    // {
    //   path: 'brandscategories',
    //   component: BrandscategoriesComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    // },
    {
      path: 'particulars',
      component: ParticularsComponent, canActivate: [AuthGuard]
    },
    {
      path: 'employees',
      component: EmployeesComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'customers',
      component: CustomersComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'customerprofile',
      component: CustomerprofileComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'suppliers',
      component: SuppliersComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'supplierprofile',
      component: SupplierprofileComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'ledgerbooks', 
      component: LedgerbooksComponent, outlet: 'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'bookstags',component: BookstagsComponent, outlet:'side3', canActivate: [AuthGuard]}
      ]
    },
    {
      path: 'ledgerdetails', 
      component: LedgerdetailsComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'ledgerfilter', 
      component: LedgerfilterComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productbuild',
      component: ProductbuildComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'brandscategories',component: BrandscategoriesComponent, outlet:'side2', canActivate: [AuthGuard]}
      ]
    },
    {
      path: 'productbuildmfc',
      component: ProductbuildmfcComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'brandscategoriesmfc',component: BrandcategoriesmfcComponent, outlet:'side4', canActivate: [AuthGuard]}
      ]
    },
    {
      path: 'billentry',
      component: BillentryComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'customers', component: CustomersComponent, outlet: 'side', canActivate: [AuthGuard]}
      ]
    },
    // {
    //   path: 'paperlayout',
    //   component: PaperlayoutComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    // },
    {
      path: 'creditbills',
      component: CreditbillsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'billedit',
      component: BilleditComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'billreturns',
      component: BillreturnsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'billdetails',
      component: BilldetailsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'billdelete',
      component: BilldeleteComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'estimation',
      component: EstimationComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigration',
      component: ProductmigrationComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigrationout',
      component: ProductmigrationoutComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigrationin',
      component: ProductmigrationinComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigrationstock',
      component: ProductmigrationstockComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigrationmanage',
      component: ProductmigrationmanageComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'barcodelayout',
      component: BarcodelayoutComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'barcodelayoutnewstock',
      component: BarcodelayoutnewstockComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'barcodelayoutnewprd',
      component: BarcodelayoutnewprdComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productfilters',
      component: ProductfiltersComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },    
    {
      path: 'walkouts',
      component: WalkoutsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'reports',
      component: ReportsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'reportdetails',
      component: ReportdetailsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'topselling',
      component: TopsellingComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'profitbills',
      component: ProfitbillsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'vouchers',
      component: VouchersComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'settings',
      component: SettingsComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'barcodelayout',component: BarcodelayoutComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
        {path: 'brandcategoriesmfc',component: BrandcategoriesmfcComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
        {path: 'brandscategories',component: BrandscategoriesComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
        {path: 'bookstags',component: BookstagsComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
        {path: 'displaybanners',component: DisplaybannersComponent, outlet:'thirdRouter', canActivate: [AuthGuard]}
      ]
    },
  ]
  },
 
  { path: 'home', component: HomeComponent },
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'sign-up', component: SignUpComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard] },

  // {
  //   path: '',
  //   redirectTo: '/sidebarl/(secondRouter:dashboard)',
  //   pathMatch: 'full'
  // }
  // ,
  // {
  //   path: '**',
  //   redirectTo:'/allinone'}
];

const isCurrentDomain : string = window.location.href.substring(8, 20).split('.')[0];

@NgModule({
  imports: [RouterModule.forRoot(isCurrentDomain == 'pos'? routes : routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
  constructor(
    public authService: AuthService,
  ) { }
 }