import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSortable} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import 'rxjs';

import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../ledgerdetails/format-datepicker';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { SupplierprofileService } from 'src/assets/services/supplierprofile.service';
import { LedgerdetailsService } from 'src/assets/services/ledgerdetails.service';

@Component({
  selector: 'app-supplierprofile',
  templateUrl: './supplierprofile.component.html',
  styleUrls: ['./supplierprofile.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class SupplierprofileComponent implements AfterViewInit, OnInit {
 
 
  panelOpenState = true;

  public fmForm: FormGroup;

  //displayedColumns = ['date', 'name', 'debit', 'credit', 'description', 'edit', 'delete'];
  // displayedColumns = ['date2', 'particulartype', 'name', 'debit', 'credit', 'tag', 'difference', 'balance', 'description', 'edit', 'delete'];
  displayedColumns = ['date2', 'debit', 'credit', 'difference', 'balance', 'description', 'edit', 'delete'];

  //displayedColumns = ['date2', 'name', 'debit', 'credit', 'description'];
  dataSource: MatTableDataSource<any>; 

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ledkeyid: any;
  leddate: any;
  ledpart: any;
  leddebit: any;
  ledcredit: any;
  leddescription: any="";
  bktag:any;
  ///////filter
  namesearch: string='';
  selection: any;
  datesearch: any;
  filterValue = {
    name: '',
    date2s: '',
    date2e: '',
  };
  custcollection: any;
  empcollection: any;
  suppcollection: any;

  tagscollection: any;
  name: any;
  particulartype: string;

  enableUpdate: boolean = false;
  tbalance: any;
  tdifference: number;

  modeofpay: string='CASH';
  pmodes: string[] = ['CASH', 'CARD', 'UPI PAYMENT'];
  otbalance: number =0;
  ofbalance: number =0;
  openingbalance: number=0;
  flitereddataary: any[]=[];
  closingbalance: number;

  constructor(private data: SupplierprofileService, private afs: AngularFirestore, public dialog: MatDialog, public datePipe: DatePipe, public fb: FormBuilder) { }

  ngAfterViewInit() {

    if(this.messageCP=="WAGES"){
      this.afs.collection<any>('LedgerWages',ref=> ref.where('book','==',this.messageCP)).valueChanges().subscribe(data => { //filtered by book
        this.dataSource = new MatTableDataSource(data); 
        this.dataSource.filterPredicate = this.createFilter();
        console.log("FilteredData:" +JSON.stringify(this.dataSource.filteredData));
        // this.dataSource.sort = this.sort;
  
        // //sorting for date column
        // this.dataSource.sortingDataAccessor = (item, property) => {
        // //console.log(item)
        //   switch (property) {
        //     case 'date2': {
        //       return (moment(item.date2, "DD-MM-YYYY").toDate());
        //     }
        //     default: return item[property];
        //   }
        // };
  
        this.dataSource.paginator=this.paginator;
        //It searches name and description columns. To search in entire table skip it
        //this.dataSource.filterPredicate = (data, filter) =>
        //(data.name.indexOf(filter) !== -1 || data.description.indexOf(filter) !== -1) || (data.date2.indexOf(filter) !== -1);
        //----------------------------------------------------------------------  
        
  
  
      
      })
    }
    else if(this.messageCP=="DISCOUNTS"){
      this.afs.collection<any>('LedgerDiscounts',ref=> ref.where('book','==',this.messageCP)).valueChanges().subscribe(data => { //filtered by book
        this.dataSource = new MatTableDataSource(data); 
        this.dataSource.filterPredicate = this.createFilter();
        console.log("FilteresData:" +JSON.stringify(this.dataSource.filteredData));
        // this.dataSource.sort = this.sort;
  
        // //sorting for date column
        // this.dataSource.sortingDataAccessor = (item, property) => {
        // //console.log(item)
        //   switch (property) {
        //     case 'date2': {
        //       return (moment(item.date2, "DD-MM-YYYY").toDate());
        //     }
        //     default: return item[property];
        //   }
        // };
  
        this.dataSource.paginator=this.paginator;
        //It searches name and description columns. To search in entire table skip it
        //this.dataSource.filterPredicate = (data, filter) =>
        //(data.name.indexOf(filter) !== -1 || data.description.indexOf(filter) !== -1) || (data.date2.indexOf(filter) !== -1);
        //----------------------------------------------------------------------  
  
      
      })
    }
    else{
      this.afs.collection<any>('SupplierLedger',ref=> ref.where('book','==',this.messageCP).orderBy('date','asc')).valueChanges().subscribe(data => { //filtered by book
        this.dataSource = new MatTableDataSource(data); 
        this.dataSource.filterPredicate = this.createFilter();
        console.log("FilteredData:" +JSON.stringify(this.dataSource.filteredData));
        // this.dataSource.sort = this.sort;
  
        // //sorting for date column
        // this.dataSource.sortingDataAccessor = (item, property) => {
        // //console.log(item)
        //   switch (property) {
        //     case 'date2': {
        //       return item.date2;
        //     }
        //     default: return item[property];
        //   }
        // };
  
        this.dataSource.paginator=this.paginator;
        //It searches name and description columns. To search in entire table skip it
        //this.dataSource.filterPredicate = (data, filter) =>
        //(data.name.indexOf(filter) !== -1 || data.description.indexOf(filter) !== -1) || (data.date2.indexOf(filter) !== -1);
        //----------------------------------------------------------------------  
        
  
  
      
      })
    }
    


  this.tagscollection = this.afs.collection('LedgerBooks', ref => ref.where('bookname', "==" , this.messageCP)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  console.log(JSON.stringify(this.tagscollection));
}

getOpeningBalance(){
  this.openingbalance =0;
  this.flitereddataary=this.dataSource.data
  console.log(this.flitereddataary)
  if(this.flitereddataary === undefined){return 0;}
  else if(this.flitereddataary != undefined){

    if(this.namesearch ==undefined || this.namesearch==''){
      this.flitereddataary=this.flitereddataary.filter(x=> ((moment(x.date2, "DD-MM-YYYY").toDate()) < (moment(this.filterValue.date2s, "DD-MM-YYYY").toDate())) )
    }else{
      console.log("ns:"+this.namesearch)
      this.flitereddataary=this.flitereddataary.filter(x=> (x.name.indexOf(this.namesearch.toUpperCase()) > -1) &&  ((moment(x.date2, "DD-MM-YYYY").toDate()) < (moment(this.filterValue.date2s, "DD-MM-YYYY").toDate())))
    }
    console.log(this.flitereddataary)

  this.openingbalance = _.sumBy(this.flitereddataary, function (a) {  
  if(a.debit>a.credit || a.credit>a.debit){
    return parseFloat(a.credit)-parseFloat(a.debit);
    }
      else{
        return 0.00;
      }
  });
  //console.log(Math.abs(tcreditdebit));
  //return Math.abs(tcreditdebit);
  //return tcreditdebit;
  }
  console.log("OPB:"+this.openingbalance);
}


getDifference(i){
  this.tdifference =0;
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){
    this.tdifference =this.dataSource.filteredData[i]['credit'] - this.dataSource.filteredData[i]['debit'];
    return this.tdifference;
}

}

// getOverallBalance(){
//   console.log("rrrr"+this.dataSource.data.length)
//   for(let i=0;i<this.dataSource.data.length;i++){
//   this.otbalance =0;
//   if(this.dataSource === undefined){return 0;}
//   else if(this.dataSource != undefined){
//     if(i==0){
//       this.otbalance =this.dataSource.data[i]['credit'] - this.dataSource.data[i]['debit'];
//     //return this.otbalance;
//     }
//     if(i>0){

//       let previousbalance=0
//       for(let j=0; j<i; j++){
//        previousbalance=previousbalance+(this.dataSource.data[j]['credit'] - this.dataSource.data[j]['debit']);
//       }

//     let currentdifference=this.dataSource.data[i]['credit'] - this.dataSource.data[i]['debit'];
//     this.otbalance =  previousbalance+currentdifference;
//     //return this.otbalance;
//     }
// }
//   }
// console.log("zzzzzzzzzzzz"+this.otbalance)
// }
// getfilteredBalance(){
//   console.log("rrrr"+this.dataSource.filteredData.length)
//   for(let i=0;i<this.dataSource.filteredData.length;i++){
//   this.ofbalance =0;
//   if(this.dataSource === undefined){return 0;}
//   else if(this.dataSource != undefined){
//     if(i==0){
//       this.ofbalance =this.dataSource.filteredData[i]['credit'] - this.dataSource.filteredData[i]['debit'];
//     //return this.otbalance;
//     }
//     if(i>0){

//       let previousbalance=0
//       for(let j=0; j<i; j++){
//        previousbalance=previousbalance+(this.dataSource.filteredData[j]['credit'] - this.dataSource.filteredData[j]['debit']);
//       }

//     let currentdifference=this.dataSource.filteredData[i]['credit'] - this.dataSource.filteredData[i]['debit'];
//     this.ofbalance =  previousbalance+currentdifference;
//     //return this.otbalance;
//     }
// }
//   }
// console.log("yyyyyyyyyy"+this.ofbalance)
// }
getBalance(i){
  this.tbalance =0;
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){
    if(i>0){

      let previousbalance=0
      for(let j=0; j<i; j++){
       previousbalance=previousbalance+(this.dataSource.filteredData[j]['credit'] - this.dataSource.filteredData[j]['debit']);
      }

    // let previousdifference=this.dataSource.filteredData[i-1]['credit'] - this.dataSource.filteredData[i-1]['debit'];
    let currentdifference=this.dataSource.filteredData[i]['credit'] - this.dataSource.filteredData[i]['debit'];
    this.tbalance =  previousbalance+currentdifference;
    return this.tbalance;
    }
    if(i==0){
      this.tbalance =this.openingbalance+this.dataSource.filteredData[i]['credit'] - this.dataSource.filteredData[i]['debit'];
    return this.tbalance;
    }
}
}

getTotalDebit(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let tdebit = _.sumBy(this.dataSource.filteredData, function (a) {
     return parseFloat(a.debit);
});
return tdebit;
}
}


getTotalCredit(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let tcredit = _.sumBy(this.dataSource.filteredData, function (a) {
     return parseFloat(a.credit);
});
return tcredit;
}
}


getTotal(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){
let tcreditdebit = _.sumBy(this.dataSource.filteredData, function (a) {  
  if(a.debit>a.credit || a.credit>a.debit){
    return parseFloat(a.debit)-parseFloat(a.credit);
    }
      else{
        return 0.00;
      }
});
return Math.abs(tcreditdebit);
}
}


  applyFilter1(filterValue: string) {
    this.filterValue.name = filterValue.trim().toUpperCase(); 
    //filterValue = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify(this.filterValue);  
    console.log(this.dataSource.filter);
    this.getOpeningBalance();
    this.getTotalDebit();
    this.getTotalCredit();
    this.getTotal();
    // this.getOverallBalance();
    // this.getfilteredBalance();
  };
  applyFilter2(filterValue: string) {
    //console.log(this.datePipe.transform(this.datesearch.start._d,"dd-MM-yyyy"));
    //console.log(this.datePipe.transform(this.datesearch.end._d,"dd-MM-yyyy"));
    // this.filterValue.date2s = this.datePipe.transform(this.datesearch.start._d,"dd-MM-yyyy"); 
    // this.filterValue.date2e = this.datePipe.transform(this.datesearch.end._d,"dd-MM-yyyy"); 
    this.filterValue.date2s = this.datePipe.transform(new Date(this.datesearch.start),"dd-MM-yyyy"); 
    this.filterValue.date2e = this.datePipe.transform(new Date(this.datesearch.end),"dd-MM-yyyy"); 
    this.dataSource.filter = JSON.stringify(this.filterValue);   
    console.log("S: "+this.filterValue.date2s);
    console.log("E: "+this.filterValue.date2e);
    this.getOpeningBalance();
    this.getTotalDebit();
    this.getTotalCredit();
    this.getTotal();
    // this.getOverallBalance();
    // this.getfilteredBalance();
  };

  // createFilter(): (data: any, filter: string) => boolean {
  //   let filterFunction = function(data, filter): boolean {
  //     let searchTerms = JSON.parse(filter);
  //     return (data.name.indexOf(searchTerms.name) !== -1)
  //       && (data.date2 >= (searchTerms.date2s)
  //       && data.date2 <= (searchTerms.date2e))
  //   }
  //   return filterFunction;
  // }


  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      console.log("CURRDate:" +moment(data.date2, "DD-MM-YYYY").toDate());
      console.log("SDMoment:" +moment(searchTerms.date2s, "DD-MM-YYYY").toDate());
      console.log("EDMoment:" +moment(searchTerms.date2e, "DD-MM-YYYY").toDate());
      if((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
      && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
      {
        console.log(true);
      }
      else{
        console.log(false);
      }
      return  ((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
        && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
    }
    return filterFunction;
  }

  trackByUid(index, item) {
    return item.uid;
  }


  messageCP: string;

  ngOnInit() {

    this.data.currentMessageCP.subscribe(messageCP => this.messageCP = messageCP)

    this.Form();  

    this.custcollection = this.afs.collection('Customers',ref=> ref.orderBy('customerName')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    this.empcollection = this.afs.collection('Employees',ref=> ref.orderBy('employeeName')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.suppcollection = this.afs.collection('Suppliers',ref=> ref.orderBy('supplierName')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


  //   this.sort.sort(<MatSortable>{
  //     id: 'date2',
  //     start: 'asc',
  //   }
  // );
  
  }

   
    // Reactive form
    Form() {
      this.fmForm = this.fb.group({
        ledgerdate: [''],
        ledgerdebit: [''],
        ledgercredit: [''],
        ledgerdescription: [''],
        ledgerpaymentmode: [''],
      })
    }

    // Accessing form control using getters
    get ledgerdate() {
      return this.fmForm.get('ledgerdate');
    }

    get ledgerdebit() {
      return this.fmForm.get('ledgerdebit');
    }

    get ledgercredit() {
      return this.fmForm.get('ledgercredit');
    }
  
    get ledgerdescription() {
      return this.fmForm.get('ledgerdescription');
    }

    get ledgerpaymentmode() {
      return this.fmForm.get('ledgerpaymentmode');
    }

    // Reset form's values
    ResetForm() {
      this.fmForm.reset();
      this.enableUpdate=false;
    }
    

    submitLedgerData() {
      this.add();
      this.ResetForm();  // Reset form when clicked on reset button
    };
 

    add() {
      if(this.leddebit=="" || this.leddebit==null){this.leddebit=0};
      if(this.ledcredit=="" || this.ledcredit==null){this.ledcredit=0};

      let ids =this.afs.createId();     
      this.afs.collection("SupplierLedger").doc(ids).set({
        book: this.messageCP,
        KEYID: ids,
        date: this.leddate,
        date2: this.datePipe.transform(this.leddate,"dd-MM-yyyy"),
        debit: this.leddebit,
        credit: this.ledcredit,
        description: this.leddescription,
        paymentmode: this.modeofpay,
      })
        .catch((err) => {
          console.log(err);
        })
    }

    read(ledg) {  
console.log(this.name);
      this.ledkeyid = ledg.KEYID;
      this.leddate = ((moment(ledg.date2, "DD-MM-YYYY").toDate()));
      console.log(((moment(ledg.date2, "DD-MM-YYYY").toDate())));

      this.modeofpay=ledg.paymentmode;

      this.leddebit = ledg.debit;
      this.ledcredit = ledg.credit;
      this.leddescription = ledg.description; 
    }

    
  update(lid) {
    if(this.leddebit=="" || this.leddebit==null){this.leddebit=0};
      if(this.ledcredit=="" || this.ledcredit==null){this.ledcredit=0};

    this.afs.collection("SupplierLedger").doc(lid).update({
        date: this.leddate,
        debit: this.leddebit,
        credit: this.ledcredit,
        description: this.leddescription,
        paymentmode: this.modeofpay,
    }).then(() => {
      console.log('updatedF');
      this.ResetForm();
    })
  }

  
  delete(led) {
    this.afs.collection("SupplierLedger").doc(led.KEYID).delete().then(() => {
      console.log('deleted');
    })
  }


}