import { Component, OnInit, PipeTransform, Pipe, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { IfStmt } from '@angular/compiler';
import * as _ from 'lodash';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

@Component({
  selector: 'app-productfilters',
  templateUrl: './productfilters.component.html',
  styleUrls: ['./productfilters.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})

export class ProductfiltersComponent implements AfterViewInit, OnInit {
  firstFormGroup: FormGroup;


  showMainContent:Boolean=true;
  selectedOptions: string[] = [];

  products: any[]=[];
  productsFilter:any=[];

  myclrary: { colorid:any, color: any, colorcode: any }[]= []; //declare before constructor
  //selectedColor: { colorid:any, color: any }[]= [];
  selectedColor:any[]= [];

  brcollection:{ brandname: any }[]= [];
  selectedBrand:any[]= [];

  ctcollection: { index:any, committedcat: any }[]= [];
  selectedCategory: any[]= [];

  mysizary: { sizeid:any, size: any }[]= []; //declare before constructor
  selectedSize:any[]= [];
  sizchrtname: any;

  
 isButtonVisible = true;
  opened: boolean;
  
  sortbyrate: any;
  sortbysize: any;
  sortbycategory: any;
  sortbytag: any;
  sortbybrand: any;

  mysizaryDEFAULT: { sizeid: string; size: string; }[];
  mysizaryINCHES: { sizeid: string; size: string; }[];
  mysizaryMM: { sizeid: string; size: string; }[];
  mysizaryCM: { sizeid: string; size: string; }[];
  mysizaryFEET: { sizeid: string; size: string; }[];
  mysizaryLETTERS: { sizeid: string; size: string; }[];
  mysizaryNUMBERS: { sizeid: string; size: string; }[];

  constructor(private afs: AngularFirestore, private _formBuilder: FormBuilder) {
     
    this.afs.collection('Products')
.get().toPromise()
.then(querySnapshot => {
    querySnapshot.forEach(doc => {
      this.products.push(doc.data());
    });
})
.catch(function(error) {
    console.log("Error getting documents: ", error);
})
.then(()=>{
  this.productsFilter = {brand: ['COSCO','NIVIA'], color:['BK']};
})

}
sdbrbtnckfn(){
  this.opened=false;
  // delay noneed
  setTimeout(()=>{ 
  this.isButtonVisible = true;
  }, 200)
  }
 
  ngOnInit() { 
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });

    this.myclrary=[
    {colorid:'AB',color:'Airforce Blue',colorcode:'#01478C'},
    {colorid:'AM',color:'Aquamarine',colorcode:'aquamarine'},
    {colorid:'BK',color:'Black',colorcode:'black'},
    {colorid:'BR',color:'Brown',colorcode:'brown'},
    {colorid:'CY',color:'Cyan',colorcode:'cyan'},
    {colorid:'DG',color:'Dark Gray',colorcode:'#393939'},
    {colorid:'DN',color:'Dark Green',colorcode:'#024A00'},
    {colorid:'DP',color:'Dark Pink',colorcode:'#E10463'},
    {colorid:'FB',color:'Ferrosi Blue',colorcode:'#0B9AF6'},
    {colorid:'FG',color:'Fluroscent Green',colorcode:'#48FF43'},
    {colorid:'FO',color:'Fluroscent Orange',colorcode:'#FC8D33'},
    {colorid:'GY',color:'Golden Yellow',colorcode:'#FFDF00'},
    {colorid:'HW',color:'Half White',colorcode:'#FFFEFA'},
    {colorid:'IG',color:'Indigo',colorcode:'indigo'},
    {colorid:'JG',color:'Jet Green',colorcode:'#01A77F'},
    {colorid:'LY',color:'Lemon Yellow',colorcode:'#FFF001'},
    {colorid:'LG',color:'Light Gray',colorcode:'#B7B7B7'},
    {colorid:'LP',color:'Light Pink',colorcode:'#F8B7D9'},
    {colorid:'MG',color:'Magenta',colorcode:'magenta'},
    {colorid:'MN',color:'Maroon',colorcode:'maroon'},
    {colorid:'MC',color:'Muticolor',colorcode:''},
    {colorid:'NB',color:'Navy Blue',colorcode:'navy'},
    {colorid:'OL',color:'Olive',colorcode:'olive'},
    {colorid:'OE',color:'Orange',colorcode:'orange'},
    {colorid:'PG',color:'Parrot Green',colorcode:'#04AC01'},
    {colorid:'PL',color:'Purple',colorcode:'purple'},
    {colorid:'RD',color:'Red',colorcode:'red'},
    {colorid:'RB',color:'Royal Blue',colorcode:'#0016F3'},
    {colorid:'SN',color:'Sandal',colorcode:'#F4DEB5'},
    {colorid:'SL',color:'Silver',colorcode:'silver'},
    {colorid:'SB',color:'Sky Blue',colorcode:'#92C4FF'},
    {colorid:'TN',color:'Tan',colorcode:'tan'},
    {colorid:'TL',color:'Teal',colorcode:'teal'},
    {colorid:'TQ',color:'Turquoise',colorcode:'turquoise'},
    {colorid:'VL',color:'Violet',colorcode:'violet'},
    {colorid:'WT',color:'White',colorcode:'white'}
  ];

     
 
    this.brcollection =[];
    //this.brcollection.push({'brandname':"ADIDAS"});
    // this.brcollection = this.afs.collection('Brands').snapshotChanges()
    // .pipe(
    //   map(actions => actions.map(a => a.payload.doc.data()))
    // );
    this.afs.collection('Brands').get().toPromise().then(snapshot=>{snapshot.docs.forEach(doc => {
            this.brcollection.push({'brandname':doc.data().brandname});
    })
  })

console.log("br:"+this.brcollection);
    this.ctcollection =[];
    this.afs.collection('Categories',ref => ref.orderBy('allcategories','asc')).get().toPromise()
    .then(snapshot=>{snapshot.docs.forEach(doc=>{
      for(let i=0;i<doc.data().committed.length;i++){
      this.ctcollection.push({'index':i,'committedcat':doc.data().committed[i]});
      }
    })
    //newly added
    this.ctcollection=_.sortBy(this.ctcollection, 'committedcat'); 
    //newly added
  })

  // this.mysizary=[
  //   {sizeid:'XS',size:'XS'},
  //   {sizeid:'S',size:'S'},
  //   {sizeid:'M',size:'M'},
  //   {sizeid:'L',size:'L'},
  //   {sizeid:'XL',size:'XL'},
  //   {sizeid:'XXL',size:'XXL'},
  //   {sizeid:'XXXL',size:'XXXL'}];
   }

   ngAfterViewInit() {
    for(let i=0;i<this.myclrary.length;i++){
      var el =document.getElementById(this.myclrary[i].colorid) as HTMLElement;
       // el.style.cssText= "background-color:"+this.myclrary[i].color+" !IMPORTANT;width: 40px;height: 40px;margin:10px;border: 1px solid black;";
        el.style.cssText= "background-color:"+this.myclrary[i].colorcode+" !IMPORTANT;width: 40px;height: 40px; margin: 10px;border-radius: 10px;box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);";
        
      }
      
  var ell =document.getElementById('MC') as HTMLElement;
   ell.style.cssText= "background: linear-gradient(45deg, red, blue) !IMPORTANT;width: 40px;height: 40px; margin: 10px;border-radius: 10px;box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);";
   
  }

  updateCheckedColor(tta,i){
    tta.checked = !tta.checked;
if(tta.checked==true){
  //this.selectedColor.push({'colorid':tta.colorid, 'color': tta.color });
  this.selectedColor.push(tta.colorid);
  console.log("true",JSON.stringify(this.selectedColor));
}
if(tta.checked==false){
  //this.selectedColor=_.reject(this.selectedColor,function(el) {return el.color === tta.color; });
  this.selectedColor = this.selectedColor.filter(obj => obj !== tta.colorid);
  console.log("false",JSON.stringify(this.selectedColor));
}

this.statuscheck();
  
console.log(JSON.stringify(this.productsFilter));

  }


  updateCheckedBrand(br,i){   

    br.checked = !br.checked;
    if(br.checked==true){
      this.selectedBrand.push(br.brandname);
      console.log("true",JSON.stringify(this.selectedBrand));
    }
    if(br.checked==false){
      this.selectedBrand = this.selectedBrand.filter(obj => obj !== br.brandname);
      console.log("false",JSON.stringify(this.selectedBrand));
    }
    
this.statuscheck();
     console.log(JSON.stringify(this.productsFilter));
  }


  updateCheckedCategory(ct,i){
    this.selectedCategory.pop();
    // ct.checked = !ct.checked;
// console.log(ct.checked );

// if(ct.checked==true){
//   this.selectedCategory.push(ct.committedcat);
//   console.log("true",JSON.stringify(this.selectedCategory));
// }
// if(ct.checked==false){
//   this.selectedCategory = this.selectedCategory.filter(obj => obj !== ct.committedcat);
//   console.log("false",JSON.stringify(this.selectedCategory));
// }
this.selectedCategory.push(ct.committedcat);
  console.log("true",JSON.stringify(this.selectedCategory));

  this.mysizaryDEFAULT=[];
  this.mysizaryINCHES=[];
  this.mysizaryMM=[];
  this.mysizaryCM=[];
  this.mysizaryFEET=[];
  this.mysizaryLETTERS=[];
  this.mysizaryNUMBERS=[];
  this.brcollection =[];
  this.afs.collection('Brands').get().toPromise().then(snapshot=>{snapshot.docs.forEach(doc => {
    if(doc.data().categories){
      for(let i=0;i<doc.data().categories.length;i++){
        if(doc.data().categories[i].categoryname == this.selectedCategory){
          this.brcollection.push({'brandname':doc.data().brandname});

          this.sizchrtname = doc.data().categories[i].sizechart;
          console.log(this.sizchrtname);

        }
        if(doc.data().categories[i].categoryname != this.selectedCategory){
        }
    }
  }
})
})
.then(()=>{
if(this.sizchrtname=='DEFAULT'){
this.mysizaryDEFAULT=[
  {sizeid:'NIL',size:'NIL'},
    {sizeid:'XS',size:'XS'},
    {sizeid:'S',size:'S'},
    {sizeid:'M',size:'M'},
    {sizeid:'L',size:'L'},
    {sizeid:'XL',size:'XL'},
    {sizeid:'XXL',size:'XXL'},
    {sizeid:'XXXL',size:'XXXL'},
    {sizeid:'4XL',size:'5XL'},
    {sizeid:'5XL',size:'5XL'}];
}
if(this.sizchrtname=='INCHES'){
  this.mysizaryINCHES=[
    {sizeid:'1H',size:'1H'},
    {sizeid:'2',size:'2'},
    {sizeid:'3',size:'3'},
    {sizeid:'4',size:'4'},
    {sizeid:'5',size:'5'},
    {sizeid:'6',size:'6'},
    {sizeid:'7',size:'7'},
    {sizeid:'8',size:'8'},
    {sizeid:'9',size:'9'},
    {sizeid:'10',size:'10'},
    {sizeid:'11',size:'11'},
    {sizeid:'12',size:'12'},
    {sizeid:'13',size:'13'},
    {sizeid:'14',size:'14'},
    {sizeid:'15',size:'15'},
    {sizeid:'16',size:'16'},
    {sizeid:'17',size:'17'},
    {sizeid:'18',size:'18'},
    {sizeid:'19',size:'19'},
    {sizeid:'20',size:'20'},
    {sizeid:'21',size:'21'},
    {sizeid:'22',size:'22'},
    {sizeid:'23',size:'23'},
    {sizeid:'24',size:'24'},
    {sizeid:'25',size:'25'},
    {sizeid:'26',size:'26'},
    {sizeid:'27',size:'27'},
    {sizeid:'28',size:'28'},
    {sizeid:'29',size:'29'},
    {sizeid:'30',size:'30'},
    {sizeid:'31',size:'31'},
    {sizeid:'32',size:'32'},
    {sizeid:'33',size:'33'},
    {sizeid:'34',size:'34'},
    {sizeid:'35',size:'35'},
    {sizeid:'36',size:'36'},
    {sizeid:'38',size:'38'},
    {sizeid:'40',size:'40'},
    {sizeid:'42',size:'42'},
    {sizeid:'44',size:'44'},
  ];
}
if(this.sizchrtname=='MM'){
  this.mysizaryMM=[
    {sizeid:'1',size:'1'},
    {sizeid:'2',size:'2'},
    {sizeid:'3',size:'3'},
    {sizeid:'4',size:'4'},
    {sizeid:'5',size:'5'},
    {sizeid:'6',size:'6'},
    {sizeid:'7',size:'7'},
    {sizeid:'8',size:'8'},
    {sizeid:'9',size:'9'},
    {sizeid:'10',size:'10'},
    {sizeid:'11',size:'11'},
    {sizeid:'12',size:'12'},
    {sizeid:'13',size:'13'},
    {sizeid:'14',size:'14'},
    {sizeid:'15',size:'15'},
    {sizeid:'16',size:'16'},
    {sizeid:'17',size:'17'},
    {sizeid:'18',size:'18'},
    {sizeid:'19',size:'19'},
    {sizeid:'20',size:'20'},
    {sizeid:'21',size:'21'},
    {sizeid:'22',size:'22'},
    {sizeid:'23',size:'23'},
    {sizeid:'24',size:'24'},
    {sizeid:'25',size:'25'},
    {sizeid:'26',size:'26'},
    {sizeid:'27',size:'27'},
    {sizeid:'28',size:'28'},
    {sizeid:'29',size:'29'},
    {sizeid:'30',size:'30'},
    {sizeid:'31',size:'31'},
    {sizeid:'35',size:'35'},
    {sizeid:'45',size:'45'},
    {sizeid:'55',size:'55'},
    {sizeid:'65',size:'65'},
    {sizeid:'75',size:'75'},
  ];
}
if(this.sizchrtname=='CM'){
  this.mysizaryCM=[
    {sizeid:'1',size:'1'},
    {sizeid:'2',size:'2'},
    {sizeid:'3',size:'3'},
    {sizeid:'4',size:'4'},
    {sizeid:'5',size:'5'},
    {sizeid:'6',size:'6'},
    {sizeid:'7',size:'7'},
    {sizeid:'8',size:'8'},
    {sizeid:'9',size:'9'},
    {sizeid:'10',size:'10'},
    {sizeid:'11',size:'11'},
    {sizeid:'12',size:'12'},
    {sizeid:'13',size:'13'},
    {sizeid:'14',size:'14'},
    {sizeid:'15',size:'15'},
    {sizeid:'16',size:'16'},
    {sizeid:'17',size:'17'},
    {sizeid:'18',size:'18'},
    {sizeid:'19',size:'19'},
    {sizeid:'20',size:'20'},
    {sizeid:'22',size:'22'},
    {sizeid:'24',size:'24'},
    {sizeid:'26',size:'26'},
    {sizeid:'28',size:'28'},
    {sizeid:'30',size:'30'},
    {sizeid:'32',size:'32'},
    {sizeid:'34',size:'34'},
    {sizeid:'36',size:'36'},
    {sizeid:'38',size:'38'},
    {sizeid:'40',size:'40'},
    {sizeid:'42',size:'42'},
    {sizeid:'44',size:'44'},
    {sizeid:'46',size:'46'},
    {sizeid:'48',size:'48'},
    {sizeid:'50',size:'40'},
    {sizeid:'52',size:'52'},
  ];
}

if(this.sizchrtname=='FEET'){
  this.mysizaryFEET=[
    {sizeid:'1',size:'1'},
    {sizeid:'2',size:'2'},
    {sizeid:'3',size:'3'},
    {sizeid:'4',size:'4'},
    {sizeid:'5',size:'5'},
    {sizeid:'6',size:'6'},
    {sizeid:'7',size:'7'},
  ];
}
if(this.sizchrtname=='LETTERS'){
  this.mysizaryLETTERS=[
    {sizeid:'S',size:'S'},
    {sizeid:'S1',size:'S1'},
    {sizeid:'S2',size:'S2'},
    {sizeid:'S3',size:'S3'},
    {sizeid:'M',size:'M'},
    {sizeid:'M1',size:'M1'},
    {sizeid:'M2',size:'M2'},
    {sizeid:'M3',size:'M3'},
    {sizeid:'M4',size:'M4'},
    {sizeid:'L',size:'L'},
    {sizeid:'L1',size:'L1'},
    {sizeid:'L2',size:'L2'},
    {sizeid:'L3',size:'L3'},
    {sizeid:'L4',size:'L4'},
    {sizeid:'XL',size:'XL'},
    {sizeid:'XL1',size:'XL1'},
    {sizeid:'XL2',size:'XL2'},
    {sizeid:'XL3',size:'XL3'},
    {sizeid:'XL4',size:'XL4'},
    {sizeid:'XXL',size:'XXL'},
    {sizeid:'XXL1',size:'XXL1'},
    {sizeid:'XXL2',size:'XXL2'},
    {sizeid:'XXL3',size:'XXL3'},
    {sizeid:'XXL4',size:'XXL4'},
    {sizeid:'XXXL',size:'XXXL'},
    {sizeid:'XXXL1',size:'XXXL1'},
    {sizeid:'XXXL2',size:'XXXL2'},
    {sizeid:'XXXL3',size:'XXXL3'},
  ];
}


if(this.sizchrtname=='NUMBERS'){
  this.mysizaryNUMBERS=[
    {sizeid:'1',size:'1'},
    {sizeid:'2',size:'2'},
    {sizeid:'3',size:'3'},
    {sizeid:'4',size:'4'},
    {sizeid:'5',size:'5'},
    {sizeid:'6',size:'6'},
    {sizeid:'7',size:'7'},
    {sizeid:'8',size:'8'},
    {sizeid:'9',size:'9'},
    {sizeid:'10',size:'10'},
    {sizeid:'11',size:'11'},
    {sizeid:'12',size:'12'},
    {sizeid:'13',size:'13'},
    {sizeid:'14',size:'14'},
    {sizeid:'15',size:'15'},
    {sizeid:'16',size:'16'},
    {sizeid:'17',size:'17'},
    {sizeid:'18',size:'18'},
    {sizeid:'19',size:'19'},
    {sizeid:'20',size:'20'},
    {sizeid:'25',size:'25'},
    {sizeid:'30',size:'30'},
    {sizeid:'35',size:'35'},
    {sizeid:'40',size:'40'},
    {sizeid:'45',size:'45'},
    {sizeid:'50',size:'50'},
    {sizeid:'55',size:'55'},
    {sizeid:'60',size:'60'},
    {sizeid:'65',size:'65'},
    {sizeid:'70',size:'70'},
    {sizeid:'75',size:'75'},
    {sizeid:'80',size:'80'},
    {sizeid:'85',size:'85'},
    {sizeid:'90',size:'90'},
    {sizeid:'95',size:'95'},
    {sizeid:'100',size:'100'},
  ];
}
})
this.statuscheck();




  }
  

  updateCheckedSize(sz,i){
    sz.checked = !sz.checked;
if(sz.checked==true){
  this.selectedSize.push(sz.size);
  console.log("true",JSON.stringify(this.selectedSize));
}
if(sz.checked==false){
  this.selectedSize = this.selectedSize.filter(obj => obj !== sz.size);
  console.log("false",JSON.stringify(this.selectedSize));
}
this.statuscheck();
  }

statuscheck(){

  if (this.selectedBrand.length == 0 && this.selectedCategory.length == 0 && this.selectedSize.length == 0 && this.selectedColor.length == 0) {
    this.productsFilter = '';
  }
  else if (this.selectedBrand.length == 0 && this.selectedCategory.length == 0 && this.selectedSize.length == 0 && this.selectedColor.length != 0) {
    this.productsFilter = {color: this.selectedColor};
  }
  else if (this.selectedBrand.length == 0 && this.selectedCategory.length == 0 && this.selectedSize.length != 0 && this.selectedColor.length == 0) {
    this.productsFilter = {size: this.selectedSize};
  }
  else if (this.selectedBrand.length == 0 && this.selectedCategory.length == 0 && this.selectedSize.length != 0 && this.selectedColor.length != 0) {
    this.productsFilter = {size: this.selectedSize, color: this.selectedColor};
  }
  else if (this.selectedBrand.length == 0 && this.selectedCategory.length != 0 && this.selectedSize.length == 0 && this.selectedColor.length == 0) {
    this.productsFilter = {category: this.selectedCategory};
  }
  else if (this.selectedBrand.length == 0 && this.selectedCategory.length != 0 && this.selectedSize.length == 0 && this.selectedColor.length != 0) {
    this.productsFilter = {category: this.selectedCategory, color: this.selectedColor};
  }
  else if (this.selectedBrand.length == 0 && this.selectedCategory.length != 0 && this.selectedSize.length != 0 && this.selectedColor.length == 0) {
    this.productsFilter = {category: this.selectedCategory, size: this.selectedSize, };
  }
  else if (this.selectedBrand.length == 0 && this.selectedCategory.length != 0 && this.selectedSize.length != 0 && this.selectedColor.length != 0) {
    this.productsFilter = {category: this.selectedCategory, size: this.selectedSize, color: this.selectedColor};
  }
  else if (this.selectedBrand.length != 0 && this.selectedCategory.length == 0 && this.selectedSize.length == 0 && this.selectedColor.length == 0) {
    this.productsFilter = {brand: this.selectedBrand};
  }
  else if (this.selectedBrand.length != 0 && this.selectedCategory.length == 0 && this.selectedSize.length == 0 && this.selectedColor.length != 0) {
    this.productsFilter = {brand: this.selectedBrand, color: this.selectedColor};
  }
  else if (this.selectedBrand.length != 0 && this.selectedCategory.length == 0 && this.selectedSize.length != 0 && this.selectedColor.length == 0) {
    this.productsFilter = {brand: this.selectedBrand, size: this.selectedSize};
  }
  else if (this.selectedBrand.length != 0 && this.selectedCategory.length == 0 && this.selectedSize.length != 0 && this.selectedColor.length != 0) {
    this.productsFilter = {brand: this.selectedBrand, size: this.selectedSize, color: this.selectedColor};
  }
  else if (this.selectedBrand.length != 0 && this.selectedCategory.length != 0 && this.selectedSize.length == 0 && this.selectedColor.length == 0) {
    this.productsFilter = {brand: this.selectedBrand, category: this.selectedCategory};
  }
  else if (this.selectedBrand.length != 0 && this.selectedCategory.length != 0 && this.selectedSize.length == 0 && this.selectedColor.length != 0) {
    this.productsFilter = {brand: this.selectedBrand, category: this.selectedCategory, color: this.selectedColor};
  }
  else if (this.selectedBrand.length != 0 && this.selectedCategory.length != 0 && this.selectedSize.length != 0 && this.selectedColor.length == 0) {
    this.productsFilter = {brand: this.selectedBrand, category: this.selectedCategory, size: this.selectedSize, };
  }
  else if (this.selectedBrand.length != 0 && this.selectedCategory.length != 0 && this.selectedSize.length != 0 && this.selectedColor.length != 0) {
    this.productsFilter = {brand: this.selectedBrand, category: this.selectedCategory, size: this.selectedSize, color: this.selectedColor};
  }

}


hightolowrate(){
  // console.log(this.productsFilter.color)
  // //let sortedBuilds = this.products.sort((a, b) => a.rate < b.rate ? -1 : a.rate > b.rate ? 1 : 0) //ascending
  // //let sortedBuilds = this.products.sort((a, b) => a.rate > b.rate ? -1 : a.rate < b.rate ? 1 : 0) //decending
  // this.products =this.products.sort((a, b) => a.code < b.code ? -1 : a.code > b.code ? 1 : 0)  //ascending code
  // console.log('Sorted by name property')
  // console.log(this.products)
  console.log("called htl")
  this.products = this.products.sort((a, b) => a.rate > b.rate ? -1 : a.rate < b.rate ? 1 : 0) //descending rate
  this.statuscheck();
}

lowtohighrate(){  
  console.log("called lth")
    this.products = this.products.sort((a, b) => a.rate < b.rate ? -1 : a.rate > b.rate ? 1 : 0) //ascending rate
    this.statuscheck();
}

largetosmallsize(){  
  if(this.sizchrtname=='DEFAULT'){
  var sortOrder = ['5XL','4XL','XXXL', 'XXL', 'XL', 'L', 'M', 'S', 'XS', 'NIL'];
  }
  if(this.sizchrtname=='INCHES'){ 
    var sortOrder = ['44','42','40','38','36','35','34','33','32','31','30',
                     '29','28','27','26','25','24','23','22','21','20',
                     '19','18','17','16','15','14','13','12','11','10',
                     '9','8','7','6','5','4','3','2','1H'];
    }
    if(this.sizchrtname=='CM'){ 
      var sortOrder = ['52',
                       '50','48','46','44','42',
                       '40','38','36','34','32',
                       '30','28','26','24','22','20',
                       '19','18','17','16','15','14','13','12','11','10',
                       '9','8','7','6','5','4','3','2','1'];
      }
      if(this.sizchrtname=='CM'){ 
        var sortOrder = ['75','65','55','45','35','31',
                         '30','29','28','27','26','25','24','23','22','21','20',
                         '19','18','17','16','15','14','13','12','11','10',
                         '9','8','7','6','5','4','3','2','1'];
        }        
      if(this.sizchrtname=='FEET'){ 
        var sortOrder = ['7','6','5','4','3','2','1'];
        }
        if(this.sizchrtname=='LETTERS'){
          var sortOrder = ['XXXL3','XXXL2','XXXL1','XXXL', 'XXL4','XXL3','XXL2','XXL1','XXL', 'XL4','XL3','XL2','XL1','XL', 'L4','L3','L2','L1','L', 'M4','M3','M2','M1','M', 'S3','S2','S1','S'];
          }
          if(this.sizchrtname=='NUMBERS'){
            var sortOrder = ['100','95','90','85','80','75','70','65','60',
            '55','50','45','40','35','30','25','20','19','18','17','16','15','14','13','12','11','10','9','8',
            '7','6','5','4','3','2','1'
          ];
            }
    this.products = this.products.sort((a, b) => sortOrder.indexOf(a.size) - sortOrder.indexOf(b.size));
    this.statuscheck();
}
smalltolargesize(){ 
  if(this.sizchrtname=='DEFAULT'){ 
  var sortOrder = ['NIL', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', '4XL', '5XL'];
  }
  if(this.sizchrtname=='INCHES'){ 
    var sortOrder = ['1H','2','3','4','5','6','7','8','9','10',
                     '11','12','13','14','15','16','17','18','19','20',
                     '21','22','23','24','25','26','27','28','29','30',
                     '31','32','33','34','35','36','38','40','42','44'];
    }
    if(this.sizchrtname=='CM'){ 
      var sortOrder = ['1','2','3','4','5','6','7','8','9','10',
      '11','12','13','14','15','16','17','18','19','20','22','24','26','28','30',
                       '32','34','36','38','40',
                       '42','44','46','48','50',
                       '52'];
      }
      if(this.sizchrtname=='MM'){ 
        var sortOrder = ['1','2','3','4','5','6','7','8','9','10',
        '11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30',
                         '31','35','45','55','65','65'];
        }
      if(this.sizchrtname=='FEET'){ 
        var sortOrder = ['1','2','3','4','5','6','7'];
        }
        if(this.sizchrtname=='LETTERS'){ 
          var sortOrder = ['S','S1','S2','S3', 'M','M1','M2','M3','M4', 'L','L1','L2','L3','L4', 'XL','XL1','XL2','XL3','XL4', 'XXL','XXL1','XXL2','XXL3','XXL4', 'XXXL','XXXL1','XXXL2','XXXL3'];
          }
          if(this.sizchrtname=='NUMBERS'){ 
            var sortOrder = ['1','2','3','4', '5','6','7','8','9',
                      '10','11','12','13','14','15','16','17','18','19','20','25','30','35',
                      '40','45','50','55','60','65','70','75','80','85','90',
                      '95','100'
                    ];
            }
  this.products = this.products.sort((a, b) => sortOrder.indexOf(a.size) - sortOrder.indexOf(b.size));
  this.statuscheck();
}
atozcategory(){
  this.products = this.products.sort((a, b) => a.category < b.category ? -1 : a.category > b.category ? 1 : 0) //ascending category
  this.statuscheck();
}
ztoacategory(){
  this.products = this.products.sort((a, b) => a.category > b.category ? -1 : a.category < b.category ? 1 : 0) //descending category
  this.statuscheck();
}

bypriority(){
  var sortOrder = ['Trending', 'Popular', 'New'];
    this.products = this.products.sort((a, b) => sortOrder.indexOf(a.tag) - sortOrder.indexOf(b.tag));
    this.statuscheck();
}

atozbrand(){
  this.products = this.products.sort((a, b) => a.brand < b.brand ? -1 : a.brand > b.brand ? 1 : 0) //ascending brand
  this.statuscheck();
}
ztoabrand(){
  this.products = this.products.sort((a, b) => a.brand > b.brand ? -1 : a.brand < b.brand ? 1 : 0) //descending brand
  this.statuscheck();
}

resettogglebtns(){
  this.sortbyrate=null;
  this.sortbysize=null;
  this.sortbycategory=null;
  this.sortbytag=null;
  this.sortbybrand=null;
}

DoneButton() {
  this.showMainContent = this.showMainContent ? false : true;  
}
}
