import { Component, OnInit } from '@angular/core';
import { Drawer2Service } from "../../../assets/services/drawer2.service";
import { MatCalendarCellCssClasses } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';


declare function snipMe(): any;

@Component({
  selector: 'app-paperlayout',
  templateUrl: './paperlayout.component.html',
  styleUrls: ['./paperlayout.component.css']
})
export class PaperlayoutComponent implements OnInit {

  message:boolean;

    
  selectedDate: any;
  prcollection: any;

  // datesToHighlight = ["2019-01-22T18:30:00.000Z", "2019-01-22T18:30:00.000Z", "2019-01-24T18:30:00.000Z", "2019-01-28T18:30:00.000Z", "2019-01-24T18:30:00.000Z", "2019-01-23T18:30:00.000Z", "2019-01-22T18:30:00.000Z", "2019-01-25T18:30:00.000Z"];

  constructor(private data: Drawer2Service,private afs: AngularFirestore) { }

  ngOnInit() {

snipMe();

this.prcollection =[];
this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , '08-12-2019')).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

this.data.currentMessage.subscribe(message => this.message = message)
console.log("PL"+this.message);
    }

  

    onSelect(event){
      console.log(event);
      this.selectedDate = event;
    }
  
    // dateClass() {
    //   return (date: Date): MatCalendarCellCssClasses => {
    //     const highlightDate = this.datesToHighlight
    //       .map(strDate => new Date(strDate))
    //       .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());
        
    //     return highlightDate ? 'special-date' : '';
    //   };
    // }
    
}
