import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';

import {DatePipe} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { FlexLayoutModule } from '@angular/flex-layout';

import { QRCodeModule } from 'angularx-qrcode';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule, MatTooltipModule, MatChipsModule, MatSnackBarModule, MatCardModule, MatIconModule, MatTableModule, MatSortModule, MatPaginatorModule, MatInputModule, MatAutocompleteModule, MatButtonModule, MatRadioModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule, MatCheckboxModule, MatListModule, MatProgressBarModule, MatGridListModule, MatStepperModule, MatSidenavModule, MatToolbarModule, MatBottomSheetModule, MatButtonToggleModule} from '@angular/material';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatTableExporterModule } from 'mat-table-exporter';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import {NgxPrintModule} from 'ngx-print';
import {ConfirmationDialog} from './pos/productbuild/confirmation-dialog.component';
import {ConfirmationDialogmfc} from './pos/productbuildmfc/confirmationmfc-dialog.component';
import { ProductdetailsComponent } from './pos/productdetails/productdetails.component';
import { ParticularsComponent } from './pos/particulars/particulars.component';
import { LedgerdetailsComponent } from './pos/ledgerdetails/ledgerdetails.component';
import { SidebarlComponent } from './pos/sidebarl/sidebarl.component';
import { PaperlayoutComponent } from './pos/paperlayout/paperlayout.component';
import { HomeComponent } from './pos/home/home.component';
import { ProductbuildComponent } from './pos/productbuild/productbuild.component';
import { ProductbuildmfcComponent } from './pos/productbuildmfc/productbuildmfc.component';
import { Drawer2Service } from 'src/assets/services/drawer2.service';
import { BillentryComponent } from './pos/billentry/billentry.component';
import { ProductmigrationComponent } from './pos/productmigration/productmigration.component';
import { BarcodelayoutComponent } from './pos/barcodelayout/barcodelayout.component';
import { filterunique } from 'src/assets/shared/sharedpipes/filterunique.pipe';
import { ProductfiltersComponent } from './pos/productfilters/productfilters.component';
import { filteruniquebrand } from 'src/assets/shared/sharedpipes/filteruniquebrand.pipe';
import { filteruniquecategory } from 'src/assets/shared/sharedpipes/filteruniquecategory.pipe';
import { filteruniquesize } from 'src/assets/shared/sharedpipes/filteruniquesize.pipe';
import { filteruniquecolor } from 'src/assets/shared/sharedpipes/filteruniquecolor.pipe';
import { filteruniquecode } from 'src/assets/shared/sharedpipes/filteruniquecode.pipe';
import { filterproduct } from 'src/assets/shared/sharedpipes/filterproduct.pipe';
import { GroupByPipe } from 'src/assets/shared/sharedpipes/group-by.pipe';
import { NumberToWordsPipe } from 'src/assets/shared/sharedpipes/number-to-words.pipe';

import {NgPipesModule} from 'ngx-pipes';
import { AvatarModule } from 'ngx-avatar';

import { BilldetailsComponent } from './pos/billdetails/billdetails.component';
import { EmployeesComponent } from './pos/employees/employees.component';
import { CustomersComponent } from './pos/customers/customers.component';
import { SettingsComponent } from './pos/settings/settings.component';
import { BillreturnsComponent } from './pos/billreturns/billreturns.component';
import { Drawer3Service } from 'src/assets/services/drawer3.service';
import { BrandscategoriesComponent } from './pos/brandscategories/brandscategories.component';
import { BilldeleteComponent, BottomSheetBillDelete } from './pos/billdelete/billdelete.component';
import { ProductmigrationoutComponent } from './pos/productmigrationout/productmigrationout.component';
import { DashboardComponent } from './pos/dashboard/dashboard.component';
import { ProductmigrationinComponent } from './pos/productmigrationin/productmigrationin.component';
import { ProductmigrationstockComponent } from './pos/productmigrationstock/productmigrationstock.component';
import { ProductmigrationmanageComponent } from './pos/productmigrationmanage/productmigrationmanage.component';
import { BarcodelayoutnewstockComponent } from './pos/barcodelayoutnewstock/barcodelayoutnewstock.component';
import { Drawer10Service } from 'src/assets/services/drawer10.service';
import { Drawer11Service } from 'src/assets/services/drawer11.service';
import { Drawer12Service } from 'src/assets/services/drawer12.service';
import { Drawer13Service } from 'src/assets/services/drawer13.service';
import { Drawer14Service } from 'src/assets/services/drawer14.service';
import { Drawer15Service } from 'src/assets/services/drawer15.service';
import { ReportsComponent } from './pos/reports/reports.component';
import { DisplaybannersComponent } from './pos/displaybanners/displaybanners.component';
import { CustomerprofileComponent, DialogDataExampleDialog, DialogDataExampleDialogERONE, DialogDataExampleDialogERTWO, DialogDataExampleDialogERVIEW, DialogDataExampleDialogERVIEWTP } from './pos/customerprofile/customerprofile.component';
import { CustomerprofileService } from 'src/assets/services/customerprofile.service';
import { AlterstockComponent } from './pos/alterstock/alterstock.component';
import { BarcodelayoutnewprdComponent } from './pos/barcodelayoutnewprd/barcodelayoutnewprd.component';
import { LedgerbooksComponent } from './pos/ledgerbooks/ledgerbooks.component';
import { LedgerfilterComponent } from './pos/ledgerfilter/ledgerfilter.component';
import { LedgerdetailsService } from 'src/assets/services/ledgerdetails.service';
import { BookstagsComponent } from './pos/bookstags/bookstags.component';
import { ReportdetailsComponent } from './pos/reportdetails/reportdetails.component';
import { SuppliersComponent } from './pos/suppliers/suppliers.component';
import { EstimationComponent } from './pos/estimation/estimation.component';
import { TopsellingComponent } from './pos/topselling/topselling.component';
import { ProfitbillsComponent } from './pos/profitbills/profitbills.component';
import { BrandcategoriesmfcComponent } from './pos/brandcategoriesmfc/brandcategoriesmfc.component';
import { CreditbillsComponent } from './pos/creditbills/creditbills.component';
import { BilleditComponent } from './pos/billedit/billedit.component';
import { VouchersComponent } from './pos/vouchers/vouchers.component';
import { SupplierprofileComponent } from './pos/supplierprofile/supplierprofile.component';
import { SupplierprofileService } from 'src/assets/services/supplierprofile.service';
import { WalkoutsComponent } from './pos/walkouts/walkouts.component';

import { UserdashboardComponent } from './components/userdashboard/userdashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { WindowService } from './components/sign-up/window.service';
import { AuthService } from "../assets/auth/services/auth.service";

// var config = {
//   apiKey: "AIzaSyDyKjelRh8_h2Qp-Kj-31QFO0HHY91dpDQ",
//   authDomain: "nntrial-faed5.firebaseapp.com",
//   projectId: "nntrial-faed5",
//   storageBucket: "nntrial-faed5.appspot.com",
//   messagingSenderId: "844685711201",
//   appId: "1:844685711201:web:39acb0cd547ca21ded1720",
//   measurementId: "G-GYYGJEN3JJ"
// };

var config = {
  apiKey: "AIzaSyC4s0LwXgCLiDyJyHi8zaAe5lmEiK0YTbE",
  authDomain: "nnpointofsaledl.firebaseapp.com",
  projectId: "nnpointofsaledl",
  storageBucket: "nnpointofsaledl.appspot.com",
  messagingSenderId: "660353707647",
  appId: "1:660353707647:web:c96d91380903cc67dc594a",
  measurementId: "G-23VZ36L3Y4"
};


enableProdMode();

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    QRCodeModule,
    AngularFontAwesomeModule,
    MatStepperModule,
    MatSidenavModule,
    MatToolbarModule,
    MatExpansionModule,
    MatTooltipModule,
    MatChipsModule,
    MatSnackBarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule, 
    MatBottomSheetModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressBarModule,
    MatGridListModule,
    MatCarouselModule.forRoot(),
    MatTableExporterModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    NgxPrintModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireDatabaseModule, //database
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    NgPipesModule,
    AvatarModule,
    ],
  providers: [WindowService,AuthService,MatDatepickerModule,DatePipe,Drawer2Service,Drawer3Service,Drawer10Service,Drawer11Service,Drawer12Service,Drawer13Service,Drawer14Service,Drawer15Service,CustomerprofileService,SupplierprofileService,LedgerdetailsService],
  entryComponents: [ConfirmationDialog, ConfirmationDialogmfc, BilldeleteComponent, BottomSheetBillDelete, CustomerprofileComponent, DialogDataExampleDialog, DialogDataExampleDialogERONE, DialogDataExampleDialogERTWO, DialogDataExampleDialogERVIEW, DialogDataExampleDialogERVIEWTP],
  declarations: [ NumberToWordsPipe, GroupByPipe, filterunique, filteruniquebrand, filteruniquecategory, filteruniquesize, filteruniquecolor, filteruniquecode, filterproduct, AppComponent, ProductdetailsComponent, ParticularsComponent, LedgerdetailsComponent, SidebarlComponent, PaperlayoutComponent, HomeComponent, ProductbuildComponent, ProductbuildmfcComponent, ConfirmationDialog,ConfirmationDialogmfc, BillentryComponent, ProductmigrationComponent, BarcodelayoutComponent, ProductfiltersComponent, BilldetailsComponent, EmployeesComponent, CustomersComponent, SettingsComponent, BillreturnsComponent, BrandscategoriesComponent, BilldeleteComponent, BottomSheetBillDelete, ProductmigrationoutComponent, DashboardComponent, ProductmigrationinComponent, ProductmigrationstockComponent, ProductmigrationmanageComponent, BarcodelayoutnewstockComponent, ReportsComponent, DisplaybannersComponent, CustomerprofileComponent, DialogDataExampleDialog, DialogDataExampleDialogERONE, DialogDataExampleDialogERTWO, DialogDataExampleDialogERVIEW, DialogDataExampleDialogERVIEWTP, AlterstockComponent, BarcodelayoutnewprdComponent, BookstagsComponent, LedgerbooksComponent, LedgerfilterComponent, LedgerfilterComponent, BookstagsComponent, ReportdetailsComponent, SuppliersComponent, EstimationComponent, TopsellingComponent, ProfitbillsComponent, BrandcategoriesmfcComponent, CreditbillsComponent, BilleditComponent, VouchersComponent, SupplierprofileComponent, WalkoutsComponent, UserdashboardComponent, SignInComponent, SignUpComponent, ForgotPasswordComponent, VerifyEmailComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }

