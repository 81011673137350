import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../assets/auth/services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  user: firebase.User;
  subdomain: string;
  currentphonenumber: any;

  constructor(public afAuth: AngularFireAuth,private auth: AuthService, 
    public snackBar: MatSnackBar,
    private router: Router) { 
      this.subdomain = window.location.href.substring(8, 20).split('.')[0];
    }
    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
         duration: 2000,
         panelClass: ['snackbarcss'],
      });
      }
  ngOnInit() {
    this.afAuth.authState.subscribe(res =>{
      if(res && res.uid){
        this.currentphonenumber=res.phoneNumber
        console.log("user logged in")
      }
      else{
        console.log("user not logged in")
      }
    })
  }
  public icon2 = 'exit_to_app'; 

  public  logout() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['/sign-in']);
    })
  } 
}
