import { Component, ViewEncapsulation, OnInit, Input, ViewChildren, QueryList, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Drawer14Service } from "../../../assets/services/drawer14.service";
import { MatCalendarCellCssClasses, MatPaginator, MatSnackBar, MatSnackBarConfig, MatSort, MatTableDataSource } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as firebase from 'firebase';
export interface Item { name: string; rate: number;}

import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../ledgerdetails/format-datepicker';

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { element } from 'protractor';

declare function snipMe(): any;

@Component({
  selector: 'app-creditbills',
  templateUrl: './creditbills.component.html',
  styleUrls: ['./creditbills.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ],
  encapsulation: ViewEncapsulation.None,   //snackbar panelcss
})
export class CreditbillsComponent implements AfterViewInit, OnInit {

  bcollection: any;
  myary: any;

  displayedColumns = ['timestamp', 'billdetails', 'creditsummary'];
  displayedColumns2 = ['timestamp','amountpaid','balance'];

  dataSource: MatTableDataSource<any>; 
  //dataSource2: MatTableDataSource<any>; 
  
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public crForm: FormGroup;

  crd: any;
  modeofpay: string='CASH';
  pmodes: string[] = ['CASH', 'CARD', 'UPI PAYMENT'];
  
  namesearch: string='';
  enableUpdate: boolean = false;
  currentbillrefno: any;
  currentcustname: any;
  currenttimesamp: any;
  eventdate: string;
  currentbillid: any;
  currentbillfullid: any;
  tbalance: number;
  currentbillamount: any;

  selectedDate: string;
  currentcustid: any;
  billamount: any;

constructor(private data: Drawer14Service, private afs: AngularFirestore, public fb: FormBuilder, public snackBar: MatSnackBar, private changeDetection: ChangeDetectorRef) {   }


addorreplace(array, item){
 const i=array.findIndex(_item => _item.timestamp===item.timestamp);
 if(i>-1) array[i] =item;
 else array.push(item);
}


@ViewChildren('allTheseThings') things: QueryList<any>;


ngForRendred() {

}

dataSource2: any[] = [];

ngOnInit() {
  this.Form();  
  this.eventdate=String(moment().format('DD-MM-YYYY'))

}
    
ngAfterViewInit() {

this.dataSource2 = [];

this.afs.collection<any>('Bills').doc(this.eventdate).collection('--Id--',ref=>ref.where('cbill', "==" , 'yes').where('iscleared', "==" , 'no')).valueChanges().subscribe(data => { //filtered by book
  this.dataSource = new MatTableDataSource(data); 
})


this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref=>ref.where('cbill', "==" , 'yes').where('iscleared', "==" , 'no')).get().toPromise().then(querySnapshot => {
  querySnapshot.forEach(doc => {
this.afs.collection<any>('Bills').doc(this.eventdate).collection('--Id--',ref=>ref.where('cbill', "==" , 'yes').where('iscleared', "==" , 'no')).doc(String(doc.data().currentbillid)).collection('creditsummary', ref => ref.orderBy('stimestamp','asc')).get().toPromise().then(querySnapshot => {
  querySnapshot.forEach(docz => {
  let model = { 'timestamp': docz.data().timestamp,'amountdue': docz.data().amountdue , 'amountpaid': docz.data().amountpaid , 'balance':Number(docz.data().billamount-docz.data().amountpaid) };  //get the model from the form
  this.addorreplace(this.dataSource2,model);  //add the new model object to the dataSource
    this.dataSource2 = [...this.dataSource2];  //refresh the dataSource
  })
})
  })
})

}

    // Reactive form
    Form() {
      this.crForm = this.fb.group({
        credit: [''],
        paymentmode: [''],
      })
    }
    get credit() {
      return this.crForm.get('credit');
    }
      
    get paymentmode() {
      return this.crForm.get('paymentmode');
    }
    // Reset form's values
    ResetForm() {
      this.crForm.reset();
      this.enableUpdate=false;
    }

updatecredit(){
  let ids =this.afs.createId();
  this.afs.collection('Bills').doc(String(this.currenttimesamp)).collection('--Id--').doc(String(this.currentbillid)).collection('creditsummary').doc(ids).set({
    amountpaid: Number(this.crd),
    amountdue:Number(this.currentbillamount - this.crd),
    billid:this.currentbillfullid,
    paymentmode:this.modeofpay,
    timestamp:moment().format('DD-MM-YYYY hh:mm:ss A'),
    stimestamp:firebase.firestore.FieldValue.serverTimestamp(),
  }).then(() => {
    if(this.crd !<=0 || this.crd !=''){
      let ids =this.afs.createId();
  this.afs.collection("Ledger").doc(ids).set({
    book: "SALES",
    KEYID: ids,
    date: firebase.firestore.FieldValue.serverTimestamp(),
    date2: moment().format('DD-MM-YYYY'),
    part:this.currentcustid+"+"+this.currentcustname+"+customer",
    particulartype:"customer",
    debit: 0,
    credit: this.crd,
    description: this.currentbillrefno+ "/" +this.currentbillfullid,
    tag: "BILLS",
    name: this.currentcustname
  }).then(()=>{
    this.ngAfterViewInit();
  this.enableUpdate=false;
  // this.currentbillid='';
  this.currentbillrefno='';
  this.currentbillfullid='';
  this.currentcustname='';
  this.currentcustid='';
  this.currentbillamount='';
  this.currenttimesamp='';
  })
    .catch((err) => {
      console.log(err);
    })
  }
  })
}

updatebill(a){
  this.enableUpdate=true;
  this.currentbillid=a.currentbillid;
  this.currentbillrefno=a.billrefno;
  this.currentbillfullid=a.currbillfullid;
  this.currentcustname=a.customerName;
  this.currentcustid=a.customerId;
  this.currentbillamount=a.billamount;
  this.currenttimesamp=a.timestamp.substring(0,10);
}

getBalance(i){
  this.tbalance =0;
  if(this.dataSource2 === undefined){return 0;}
  else if(this.dataSource2 != undefined){
    if(i>0){    
      let previousbalance=this.dataSource2[i-1]['amountdue']
      // for(let j=0; j<i; j++){
      //  previousbalance=previousbalance-(this.dataSource2[j]['amountpaid']);
      // }

      this.tbalance = previousbalance - this.dataSource2[i]['amountpaid'];

      if(this.tbalance==0){        
        this.afs.collection<any>('Bills').doc(this.eventdate).collection('--Id--',ref=>ref.where('cbill', "==" , 'yes').where('iscleared', "==" , 'no')).doc(String(this.currentbillid)).set({
          iscleared:"yes"
          }, { merge: true });
      }
      return this.tbalance;
    }
    if(i==0){
      this.tbalance =this.dataSource2[i]['amountdue'];
    return this.tbalance;
    }
}
}

onSelect(event){
    this.selectedDate = event;  
      this.eventdate=moment(event).format('DD-MM-YYYY');
      this.ngAfterViewInit();

}


}
