import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { User } from "../sign-up/user";
import { AuthService } from "../../../assets/auth/services/auth.service";
import { WindowService } from '../sign-up/window.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})


export class SignUpComponent implements OnInit {

  phoneNumber: any;
  reCaptchaVerifier!: any;
  verify: any;
  otpinput: string='';
  emailinput: string='';
  passwordinput: string='';
  usermobile: string='';

  otpandpasswordfield: any='hide';
  isotprequested: boolean = false;

  public phnForm: FormGroup;

  hide: boolean = true;

  visibFunction() {
    this.hide = !this.hide;
  }

  @ViewChild('usermobileid', {static: true}) usermobileidfoc: any;

  constructor(
    private fb: FormBuilder, 
    public snackBar: MatSnackBar,  
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public authService: AuthService, private win: WindowService,private router: Router, private ngZone: NgZone
  ) { }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
       panelClass: ['snackbarcss'],
    });
    }
  Form() {
    this.phnForm = this.fb.group({
      mobileinputcntrl: [''],
    })
  }
  get mobileinputcntrl() {
    return this.phnForm.get('mobileinputcntrl');
  }


  ngOnInit() {  
    this.Form();  
    this.usermobile='';
  }
  

  getOTP() {

    var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
    //if phone number
    if(phoneRegex.test(this.usermobile)){

    this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
      }
    );
    console.log(this.reCaptchaVerifier);

    console.log(this.usermobile);
    firebase
      .auth()
      .signInWithPhoneNumber("+91"+this.usermobile, this.reCaptchaVerifier)
      .then((confirmationResult) => {
        localStorage.setItem(
          'verificationId',
          JSON.stringify(confirmationResult.verificationId)
        );
      })
      .catch((error) => {
         console.log(error.message);
        // alert(error.message);
        //this.openSnackBar(error.message,"OK");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      this.otpandpasswordfield='show';

      this.isotprequested=true;
      this.disablemobileinput();
    }
    else{
      this.usermobileidfoc.nativeElement.focus();
      this.openSnackBar("Please enter valid Mobile number","OK");
    }
  }


  signup() {
    if(this.otpinput!='' && this.emailinput!='' && this.passwordinput!=''){
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;;
      if(emailRegex.test(this.emailinput)){
        if(this.passwordinput.length>=6){
      console.log(this.otpinput)


      this.afs.collection("users",ref=>ref.where('email','==',this.emailinput)).get().toPromise().then(querySnapshot => {
        if (!querySnapshot.empty) {
          this.openSnackBar("Email already in use","OK");
         }
        

else{
    this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');

    var credential = firebase.auth.PhoneAuthProvider.credential(
      this.verify,
      this.otpinput
    );

     console.log(credential);

    // this.SignUpEmailPass(this.emailinput,this.passwordinput)
    firebase
      .auth()
      .signInWithCredential(credential)
      .then((response) => {
        console.log(response);
        localStorage.setItem('user_data', JSON.stringify(response));
        //this.SignUpEmailPass(this.emailinput,this.passwordinput)

        const user = firebase.auth().currentUser;

// user.updateEmail(this.emailinput).then(() => {
//   // Update successful
//   // ...
// }).catch((error) => {
//   // An error occurred
//   // ...
// });


// user.updatePassword(this.passwordinput).then(() => {
//   // Update successful.
// }).catch((error) => {
//   // An error ocurred
//   // ...
// });

user.updateEmail(this.emailinput).then(() => {
  user.updatePassword(this.passwordinput).then(() => {
    this.SetUserData(user);
  })
})



        this.ngZone.run(() => {
          this.router.navigate(['/home']);
        });
      })
      .catch((error) => {
       // this.openSnackBar(error.message,"OK");
      });
    }
    })
    }else{
      this.openSnackBar("Password must be atleast 6 characters","OK");
    }
    }else{
      this.openSnackBar("Invalid Email","OK");
    }
  }else{
    this.openSnackBar("Form Invalid","OK");
  }
  }

  // SignUpEmailPass(email, password) {
  //   return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
  //     .then((result) => {
  //       /* Call the SendVerificaitonMail() function when new user sign
  //       up and returns promise */
  //       this.SendVerificationMail();
  //       this.SetUserData(result.user);
  //     }).catch((error) => {
  //       window.alert(error.message)
  //     })
  // }
  // SendVerificationMail() {
  //   return this.afAuth.auth.currentUser.sendEmailVerification()
  //   .then(() => {
  //     this.router.navigate(['/verify-email-address']);
  //   })
  // }
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      phone:user.phoneNumber,
      displayName: 'MY ACCOUNT',
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  disablemobileinput(){
    if(this.isotprequested==true){
      this.phnForm.controls['mobileinputcntrl'].disable()
    }
  }
    enablemobileinput(){
      //if(this.isotprequested==true){
        this.otpandpasswordfield='hide';
        this.phnForm.controls['mobileinputcntrl'].enable();
        this.isotprequested=false
      //}
  }
}



