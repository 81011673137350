import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SupplierprofileService {

  private messageSourceCP = new BehaviorSubject("8CP4atxVsOku0MbOAWS8"); //defaultmsg is 'default message'
  currentMessageCP = this.messageSourceCP.asObservable();

  constructor() { }

  changeMessageCP(messageCP: string) {
    this.messageSourceCP.next(messageCP)
  }

}