import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from "../../../assets/auth/services/auth.service";
import { Observable } from 'rxjs';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  
  constructor(
    public authService: AuthService,
    public router: Router
  ){ }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
    //uncomment this for login system  
    const phoneNumber : string = firebase.auth().currentUser.phoneNumber;
    const isCurrentDomain : string = window.location.href.substring(8, 20).split('.')[0];

    // if(this.authService.isLoggedIn !== true) {
    //   this.router.navigate(['sign-in'])
    // }
    if(this.authService.isLoggedIn == true && isCurrentDomain=='pos' && (phoneNumber == "+919123552087" || phoneNumber == "+919344823456" || phoneNumber == "+919626665262")){
      return true;
    }
     else if(this.authService.isLoggedIn == true && isCurrentDomain=='branch1' && (phoneNumber == "+919123552087" || phoneNumber == "+919344823456" || phoneNumber == "+919626665262")){
      return true;
    }
    else if(this.authService.isLoggedIn == true && isCurrentDomain=='branch2' && (phoneNumber == "+919123552087" || phoneNumber == "+919344823456" || phoneNumber == "+919626665262")){
      return true;
    }
    else{
      this.router.navigate(['sign-in'])
    }
    return false;

    // //comment this for login system
    // return true;
  }

}